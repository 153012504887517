import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { mapTo, takeUntil } from 'rxjs/operators';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { LandingService } from 'src/app/core/services/landing.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { LandingQuery } from 'src/app/core/state/landing/landing.query';
import { SortType } from 'src/app/modules/sport/models/todays-events/todays-events.model';
import {
  ActionType,
  SortFilterModel,
  defaultSortFilterOptions,
  listOfDays,
  sortOptionStorageKey,
} from 'src/app/shared/models/filter-sort.model';
import { getCookie } from 'src/app/shared/utils/cookie-actions';

@Component({
  selector: 'app-overlay-filter',
  templateUrl: './overlay-filter.component.html',
  styleUrls: ['./overlay-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverlayFilterComponent implements OnInit, OnDestroy {
  @Input() eventLocation = 'Homepage - Today'; // added for tracking
  @ViewChild('filterOptions') filterOptionsTemplate: TemplateRef<any>;

  filterRef: OverlayRef;
  isOpen$: Observable<boolean>;
  sortFilterOptions$ = new BehaviorSubject<any>(JSON.parse(defaultSortFilterOptions));
  isFilterApplied$ = new BehaviorSubject<any>(false);
  sortFilterOption = defaultSortFilterOptions;

  private readonly opened$ = new Subject();
  private readonly disposed$ = new Subject();
  private readonly backdropClass = 'filter-overlay';
  private readonly filterClick = 'Filter click';
  private readonly filterApplied = 'Filter Applied';

  constructor(
    private readonly overlay: Overlay,
    private readonly vcr: ViewContainerRef,
    private readonly localStorage: LocalStorageService,
    private readonly landingService: LandingService,
    private readonly dataLayerService: DataLayerService,
    private readonly accountQuery: AccountQuery,
    private readonly landingQuery: LandingQuery
  ) {}

  ngOnInit(): void {
    this.isOpen$ = merge(this.opened$.pipe(mapTo(true)), this.disposed$.pipe(mapTo(false)));
    this.setDefaultOptions();
  }

  ngOnDestroy(): void {
    this.disposeFilter();
  }

  openFilter(): void {
    if (this.filterRef) {
      this.cleanUp();
      return;
    }

    const overlayConfig = this.createOverlayConfig();

    this.filterRef = this.overlay.create(overlayConfig);
    this.filterRef
      .backdropClick()
      .pipe(takeUntil(this.disposed$))
      .subscribe(_ => {
        this.cleanUp();
      });

    const filterOption = new TemplatePortal(this.filterOptionsTemplate, this.vcr);

    this.filterRef.attach(filterOption);
    this.opened$.next();
    this.createDataLayerEvent(this.filterClick, { ABTestHomePage: getCookie('ABTestHomePage') });
  }

  setDefaultOptions() {
    this.getSortingOption();
    this.storeSortingOption();
  }

  disposeFilter(): void {
    if (!this.filterRef) {
      return;
    }

    this.filterRef.dispose();
    this.filterRef = undefined;
    this.disposed$.next();
    this.resetSelection();
  }

  cleanUp(): void {
    this.disposeFilter();
  }

  checkFilterApplied() {
    this.isFilterApplied$.next(JSON.stringify(this.sortFilterOptions$.getValue()) !== defaultSortFilterOptions);
  }

  applyFilter() {
    this.storeSortingOption();
    this.disposeFilter();
    this.setAction();
    this.createDataLayerEvent(this.filterApplied, { ABTestHomePage: getCookie('ABTestHomePage') });
  }

  setAction() {
    const filterSortOption = this.sortFilterOptions$
      .getValue()
      .sort((a: SortFilterModel, b: SortFilterModel) => a.actionType - b.actionType);
    filterSortOption.forEach((t: SortFilterModel) => {
      if (t.actionType === ActionType.Filter) {
        this.landingService.updateSelectedDate(t.selectedValue);
      }
      if (t.actionType === ActionType.Sort) {
        this.landingService.updateSelectedSortType(t.selectedValue);
      }
    });
  }

  resetFilter() {
    this.sortFilterOptions$.next(JSON.parse(defaultSortFilterOptions));
  }

  resetSelection() {
    this.sortFilterOptions$.next(JSON.parse(this.sortFilterOption));
  }

  sortTrackBy(index: number, sort: SortType): SortType {
    return sort;
  }

  private createDataLayerEvent(eventName: string, extraEventData?: Record<string, number | string>): void {
    const { eventLocation } = this;
    this.dataLayerService.createDataLayerEvent({
      event: eventName,
      user_id: this.accountQuery.userData?.id,
      location: eventLocation ? eventLocation : window.location.pathname,
      sort_by: this.landingQuery.sortType?.name,
      day: this.landingQuery.selectedDate?.name,
      ...(extraEventData || {}),
    });
  }

  private getSortingOption() {
    if (this.localStorage.retrieve(sortOptionStorageKey)) {
      this.sortFilterOptions$.next([this.localStorage.retrieve(sortOptionStorageKey), { ...listOfDays }]);
      this.setAction();
    }
  }

  private storeSortingOption() {
    this.localStorage.store(sortOptionStorageKey, this.sortFilterOptions$.getValue()[0]);
    this.sortFilterOption = JSON.stringify(this.sortFilterOptions$.getValue());
    this.checkFilterApplied();
  }

  private createOverlayConfig(): OverlayConfig {
    const positionStrategy = this.overlay.position().global();
    return new OverlayConfig({
      hasBackdrop: true,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      backdropClass: this.backdropClass,
      positionStrategy,
    });
  }
}
