<div class="coupon-header">
  <div class="container">
    <div class="coupon-title" i18n>Betslip</div>
    <span class="coupon-close material-icons" (click)="goBack()">{{ 'close' }}</span>
  </div>
</div>

<ng-container *ngIf="couponQuery.bestSellerCoupons$ | async as bestSellerCoupons">
  <ng-container *ngIf="bestSellerCoupons.length > 0">
    <ng-container [ngTemplateOutlet]="predefinedBets" [ngTemplateOutletContext]="{ bestSellerCoupons: bestSellerCoupons }"> </ng-container>
  </ng-container>
</ng-container>

<ng-container *ngIf="couponQuery.emptyBetslipQuicklinks$ | async as emptyBetslipQuicklinks">
  <ng-container *ngIf="emptyBetslipQuicklinks.length > 0">
    <ng-container [ngTemplateOutlet]="betslipQuicklinks" [ngTemplateOutletContext]="{ emptyBetslipQuicklinks: emptyBetslipQuicklinks }">
    </ng-container>
  </ng-container>
</ng-container>

<!-- Default template -->
<ng-container *ngIf="(couponQuery.bestSellerCoupons$ | async).length === 0 && (couponQuery.emptyBetslipQuicklinks$ | async).length === 0">
  <div class="coupon-empty">
    <div class="coupon-empty-title" i18n>Your Betslip is currently empty</div>
    <div i18n>Close Betslip and click on the odds to add your selections.</div>
  </div>
  <app-button
    i18n-text
    class="continue-betting-btn"
    text="Continue Betting"
    [customButtonStyle]="customButtonStyle"
    [buttonType]="buttonType.Highlight"
    (btnClick)="continueBetting()"
  ></app-button>
</ng-container>

<!-- Pre-defined bets template -->
<ng-template let-bestSellerCoupons="bestSellerCoupons" #predefinedBets>
  <div class="coupon-empty">
    <div class="coupon-empty-title" i18n>Your Betslip is currently empty</div>
    <div i18n>Here are some popular bets by our customers to get you started!</div>
  </div>

  <div class="predefined-bets">
    <div class="container" *ngFor="let coupon of bestSellerCoupons; trackBy: couponTrackBy">
      <div class="wrapper">
        <div class="title selection-name">
          <div *ngIf="coupon.odds.length < 4">
            <span i18n>{{ titleLabel[coupon.odds.length] }}</span>
          </div>
          <div *ngIf="coupon.odds.length > 3">{{ coupon.odds.length }}&nbsp;{{ titleLabel.default }}</div>
          <div class="usage-metric" *ngIf="coupon.bookingCount >= couponQuery.bestSellerBookingCountThreshold">
            <span class="booking-count-icon"></span>
            <span i18n> {{ coupon.bookingCount | number }} have placed this bet </span>
          </div>
        </div>
        <div class="content">
          <div>
            <div class="event-row" *ngFor="let odd of coupon.odds">
              <coupon-empty-betbuilder-odd *ngIf="odd.isBetBuilder; else notBetBuilder" [odd]="odd"></coupon-empty-betbuilder-odd>
              <ng-template #notBetBuilder><coupon-empty-odd [odd]="odd"></coupon-empty-odd></ng-template>
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="booking-code-metrics">
            <button class="booking-code" (click)="handleCopyBookingCode(coupon.bookedCouponCode)">
              <span class="material-icons">{{ 'content_copy' }}</span>
              &nbsp;
              <span class="booking-code-text" i18n>{{ coupon.bookedCouponCode }}</span>
            </button>

            <div class="total-odds">
              <span i18n class="text">Total Odds: </span>
              &nbsp;
              <span class="value">{{ coupon.totalOdds }}</span>
            </div>
          </div>
          <div class="betslip-action">
            <button class="btn add-to-betslip" (click)="addToBetslip(coupon)">
              <span class="material-icons icon">{{ 'add' }}</span>
              <span class="btn-text" i18n>Add to Betslip</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Empty betslip quicklinks template -->
<ng-template let-emptyBetslipQuicklinks="emptyBetslipQuicklinks" #betslipQuicklinks>
  <div class="coupon-empty">
    <div class="coupon-empty-title" i18n>Your Betslip is currently empty</div>
    <div i18n>Here are some quick links to get you started!</div>
  </div>
  <div class="quicklinks-container">
    <div class="quicklinks-wrapper">
      <ng-container *ngFor="let quicklink of emptyBetslipQuicklinks; trackBy: couponTrackBy">
        <div class="link-item" (click)="navigateTo(quicklink.url)">
          <div>{{ quicklink.title }}</div>
          <div class="right-arrow-icon">
            <i class="fa fa-angle-right" aria-hidden="true"></i>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
