import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { map } from 'rxjs/operators';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { MyBetsStore } from 'src/app/modules/my-bets/state/my-bets.store';
import { MyOpenBetsQuery } from 'src/app/modules/my-bets/state/my-open-bets-query';
import { MySettledBetsQuery } from 'src/app/modules/my-bets/state/my-settled-bets.query';
import { MyBetsState } from 'src/app/modules/my-bets/models/my-bets-state.model';
import { BetsTab } from 'src/app/modules/my-bets/models/my-bets-enums.model';
import { ProductType, VirtualsLeagueType } from 'src/app/shared/models/product.model';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { CashoutQuery } from 'src/app/core/state/cashout/cashout.query';
import { BetLiveDetailsModel, MyBetsContentModel } from 'src/app/modules/my-bets/models/my-bets.model';
import { LiveQuery } from 'src/app/core/state/live/live.query';
import { MatchModel } from 'src/app/shared/models/sport.model';
import { CouponStatus } from 'src/app/shared/models/coupon-details.model';

@Injectable({ providedIn: 'root' })
export class MyBetsQuery extends Query<MyBetsState> {
  myOpenBets$ = this.myOpenBetsQuery.bets$;
  // One of the stored bets is extra if there are more then 20 open bets in the system
  // It is only used to check if there are more than the configured maximum
  // For display purposes, we remove the extra one
  myOpenBetsView$ = this.myOpenBetsQuery.bets$.pipe(map(bets => (bets.length > this.couponCount ? bets.slice(0, this.couponCount) : bets)));
  openBetCount$ = this.myOpenBetsQuery.openBetsCount$;
  mySettledBets$ = this.mySettledBetsQuery.bets$;
  openLoading$ = this.myOpenBetsQuery.isLoading$;
  settledLoading$ = this.mySettledBetsQuery.isLoading$;
  selectedProductTab$ = this.select(state => state.selectedProductTab);
  selectedBetsTab$ = this.select(state => state.selectedBetsTab);
  isLiveDetailsPollingEnabled$ = this.selectedProductTab$.pipe(map(() => this.getConfig()?.myBets.pollLiveDetails as boolean));
  expandedLiveBetsCount$ = this.myOpenBetsQuery.expandedLiveBetsCount$;
  expandedLiveBetsDetails$ = this.myOpenBetsQuery.expandedLiveBetsDetails$;
  myBetsContent$ = this.select(state => state.myBetsContent);
  virtualsLeagueTabs$ = this.select(state => state.virtualsLeagueTabs);
  selectedVirtualsLeagueTab$ = this.select(state => state.selectedVirtualsLeagueTab);
  autoExpandCouponCodes$ = this.select(state => state.autoExpandCouponCodes);

  constructor(
    private readonly appConfigService: AppConfigService,
    private readonly applicationQuery: ApplicationQuery,
    private readonly cashoutQuery: CashoutQuery,
    private readonly liveQuery: LiveQuery,
    private readonly myOpenBetsQuery: MyOpenBetsQuery,
    private readonly mySettledBetsQuery: MySettledBetsQuery,
    protected myBetsStore: MyBetsStore
  ) {
    super(myBetsStore);
  }

  get hasSettledData(): boolean {
    return this.mySettledBetsQuery.hasData;
  }

  get lostBetsLoaded(): boolean {
    return this.mySettledBetsQuery.lostBetsLoaded;
  }

  get selectedBetsTab(): BetsTab {
    return this.getValue().selectedBetsTab;
  }

  get selectedProductTab(): ProductType {
    return this.getValue().selectedProductTab;
  }

  get isCashoutEnabled(): boolean {
    return this.cashoutQuery.isEnabled;
  }

  get couponCount(): number {
    return this.getConfig().myBets.couponCount;
  }

  get isInMyBets(): boolean {
    return this.applicationQuery.inMyBets;
  }

  get isLiveDetailsPollingEnabled(): boolean {
    return this.getConfig().myBets.pollLiveDetails as boolean;
  }

  get pollLiveDetailsInterval(): number {
    return this.getConfig()?.myBets.pollLiveDetailsInterval as number;
  }

  get selectedSettledStatus(): CouponStatus {
    return this.mySettledBetsQuery.selectedCouponStatus;
  }

  get myBetsContent(): MyBetsContentModel {
    return this.getValue().myBetsContent;
  }

  get virtualsLeagueTabs(): VirtualsLeagueType[] {
    return this.getValue().virtualsLeagueTabs;
  }

  get selectedVirtualsLeagueTab(): VirtualsLeagueType {
    return this.getValue().selectedVirtualsLeagueTab;
  }

  getLiveMatchStatusLabel = (event: BetLiveDetailsModel): string =>
    this.liveQuery.getLiveMatchStatusLabel(
      new MatchModel({
        eventStatus: event.eventStatus,
        matchStatus: event.matchStatus,
      })
    );

  private getConfig(): any {
    switch (this.selectedProductTab) {
      case ProductType.Virtuals:
        return this.appConfigService.get('virtuals');
      case ProductType.SportsBook:
        return this.appConfigService.get('sports');
      case ProductType.JackpotBets:
        return this.appConfigService.get('jackpotBets');
      default:
    }
  }
}
