import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, shareReplay, takeUntil } from 'rxjs/operators';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { AccountStore } from 'src/app/core/state/account/account.store';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { MyBetsQuery } from 'src/app/modules/my-bets/state/my-bets.query';
import { VirtualsCouponQuery } from 'src/app/core/state/virtuals-coupon/virtuals-coupon.query';
import { ProductType, VirtualsLeagueType } from 'src/app/shared/models/product.model';
import { CouponGroupingType } from 'src/app/shared/models/coupon.model';
import { NavBarItemActionType, NavBarItemModel } from 'src/app/shared/models/menu.model';
import { InstantCouponQuery } from 'src/app/core/state/instant-coupon/instant-coupon.query';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { DepositActions } from 'src/app/shared/models/datalayer.model';

@Component({
  selector: 'app-nav-bar-items',
  templateUrl: './nav-bar-items.component.html',
  styleUrls: ['./nav-bar-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavBarItemsComponent implements OnInit, OnDestroy {
  // Declarations
  enableSlideUps: boolean = this.appConfigService.get('enableSlideUps');
  myBetsCouponCount = this.appConfigService.get('sports').myBets.couponCount;
  sportsMyBetsUrl = this.appConfigService.get('sports').myBets.url;
  groupingType: typeof CouponGroupingType = CouponGroupingType;

  // Badge Counts
  betSlipBadgeCount$: Observable<number> = this.couponQuery.couponData$.pipe(
    map(coupon => (coupon && coupon.Odds && coupon.Odds.length) || 0)
  );

  // Virtuals Badge Counts
  virtualsBetSlipBadgeCount$: Observable<number> = this.virtualsCouponQuery.couponData$.pipe(
    map(coupon => (coupon && coupon.Odds && coupon.Odds.length) || 0)
  );

  // Instant Badge Counts
  instantBetSlipBadgeCount$: Observable<number> = this.instantCouponQuery.couponData$.pipe(
    map(coupon => {
      return (coupon && coupon.Odds && coupon.Odds.length) || 0;
    })
  );

  myBetsBadgeCount$: Observable<number | string> = this.myBetsQuery.openBetCount$.pipe(
    map(count => (count > this.myBetsCouponCount ? `${this.myBetsCouponCount}+` : count))
  );
  virtualsMyBetsBadgeCount$: Observable<number | string> = this.myBetsQuery.openBetCount$.pipe(
    map(count => (count > this.myBetsCouponCount ? `${this.myBetsCouponCount}+` : count))
  );

  navigationEndEvents$ = this.router.events.pipe(
    filter(val => val instanceof NavigationEnd),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  // Page Visibility
  isLivePageVisible = combineLatest([
    this.applicationQuery.isSlideUpVisible$,
    this.navigationEndEvents$.pipe(map((event: NavigationEnd) => event.url.indexOf('/live') > -1)),
  ]).pipe(map(([hasOpenSlideUp, isOnLivePage]) => isOnLivePage && !hasOpenSlideUp));
  isMyBetsPageVisible$ = this.navigationEndEvents$.pipe(map((event: NavigationEnd) => event.url.indexOf('/my-bets') > -1));
  isBookBetPageVisible$ = this.navigationEndEvents$.pipe(map((event: NavigationEnd) => event.url.indexOf('/book-bet') > -1));
  isMenuPageVisible$ = this.navigationEndEvents$.pipe(map((event: NavigationEnd) => event.url.indexOf('/menu') > -1));
  isRegisterPageVisible$ = this.navigationEndEvents$.pipe(map((event: NavigationEnd) => event.url.indexOf('/register') > -1));
  isDepositPageVisible$ = combineLatest([
    this.applicationQuery.isSlideUpVisible$,
    this.navigationEndEvents$.pipe(map((event: NavigationEnd) => event.url.indexOf('/account/deposit') > -1)),
  ]).pipe(map(([hasOpenSlideUp, isOnDepositPage]) => isOnDepositPage && !hasOpenSlideUp));

  isVirtualsTutorialsPageVisible$ = this.navigationEndEvents$.pipe(map((event: NavigationEnd) => event.url.indexOf('/help') > -1));

  isOnVirtualsLobby$ = this.navigationEndEvents$.pipe(
    map((event: NavigationEnd) => event?.url?.substring(event.url.lastIndexOf('/') + 1).split('?')[0] === 'virtual')
  );

  private readonly STANDARD_NAV_ITEMS = [
    {
      id: 0,
      icon: 'hamburger',
      title: 'Menu',
      type: this.enableSlideUps ? NavBarItemActionType.FunctionCall : NavBarItemActionType.Navigation,
      action: this.enableSlideUps ? 'showMenu' : '/menu',
      isSelected$: this.enableSlideUps ? this.applicationQuery.showMenu$ : this.isMenuPageVisible$,
      showLoggedIn: true,
      showLoggedOut: true,
    },
    {
      id: 1,
      icon: 'live',
      title: 'Live',
      type: NavBarItemActionType.Navigation,
      action: '/live',
      isSelected$: this.isLivePageVisible,
      showLoggedIn: true,
      showLoggedOut: true,
    },
    {
      id: 2,
      icon: 'betslip',
      title: 'Betslip',
      type: this.enableSlideUps ? NavBarItemActionType.FunctionCall : NavBarItemActionType.Navigation,
      action: this.enableSlideUps ? 'showCoupon' : '/coupon',
      badge$: this.betSlipBadgeCount$,
      isSelected$: this.applicationQuery.inCoupon$,
      showLoggedIn: true,
      showLoggedOut: true,
    },
    {
      id: 3,
      icon: 'mybets',
      title: 'My Bets',
      type: this.enableSlideUps ? NavBarItemActionType.FunctionCall : NavBarItemActionType.Navigation,
      action: this.enableSlideUps ? 'showMyBets' : '/my-bets/sports',
      badge$: this.myBetsBadgeCount$,
      isSelected$: this.enableSlideUps ? this.applicationQuery.showMyBets$ : this.isMyBetsPageVisible$,
      showLoggedIn: true,
      showLoggedOut: false,
    },
    {
      id: 3,
      icon: 'bookbet',
      title: 'Book a Bet',
      type: NavBarItemActionType.Navigation,
      action: '/book-bet',
      isSelected$: this.isBookBetPageVisible$,
      showLoggedIn: false,
      showLoggedOut: true,
    },
    {
      id: 4,
      icon: 'deposit',
      title: 'Deposit',
      type: NavBarItemActionType.Navigation,
      action: '/account/deposit',
      isSelected$: this.isDepositPageVisible$,
      showLoggedIn: true,
      showLoggedOut: false,
    },
    {
      id: 4,
      icon: 'register',
      title: 'Join',
      type: NavBarItemActionType.Navigation,
      action: '/register',
      isSelected$: this.isRegisterPageVisible$,
      showLoggedIn: false,
      showLoggedOut: true,
    },
  ];

  private readonly VIRTUALS_SCHEDULED_NAV_ITEMS = [
    {
      id: 0,
      icon: 'hamburger',
      title: 'Menu',
      type: this.enableSlideUps ? NavBarItemActionType.FunctionCall : NavBarItemActionType.Navigation,
      action: this.enableSlideUps ? 'showMenu' : '/menu',
      isSelected$: this.enableSlideUps ? this.applicationQuery.showMenu$ : this.isMenuPageVisible$,
      showLoggedIn: true,
      showLoggedOut: true,
    },
    {
      id: 1,
      icon: 'virtuals-lobby',
      title: 'Virtuals',
      type: NavBarItemActionType.FunctionCall,
      action: 'virtualsLobbyRedirect',
      isSelected$: this.isOnVirtualsLobby$,
      showLoggedIn: true,
      showLoggedOut: true,
    },
    {
      id: 2,
      icon: 'v-betslip',
      title: 'Betslip',
      type: this.enableSlideUps ? NavBarItemActionType.FunctionCall : NavBarItemActionType.Navigation,
      action: this.enableSlideUps ? 'showCoupon' : '/virtual/coupon',
      badge$: this.virtualsBetSlipBadgeCount$,
      couponDataLength$: this.virtualsBetSlipBadgeCount$,
      isSelected$: this.applicationQuery.inCoupon$,
      showLoggedIn: true,
      showLoggedOut: true,
    },
    {
      id: 3,
      icon: 'v-mybets',
      title: 'My Bets',
      type: this.enableSlideUps ? NavBarItemActionType.FunctionCall : NavBarItemActionType.Navigation,
      action: this.enableSlideUps ? 'showMyBets' : '/my-bets/virtuals',
      badge$: this.virtualsMyBetsBadgeCount$,
      isSelected$: this.enableSlideUps ? this.applicationQuery.showMyBets$ : this.isMyBetsPageVisible$,
      showLoggedIn: true,
      showLoggedOut: false,
    },
    {
      id: 3,
      icon: 'v-tutorials',
      title: 'Tutorials',
      type: NavBarItemActionType.Navigation,
      action: '/help/betking-virtual-leagues-help',
      isSelected$: this.isVirtualsTutorialsPageVisible$,
      showLoggedIn: false,
      showLoggedOut: true,
    },
    {
      id: 4,
      icon: 'deposit',
      title: 'Deposit',
      type: NavBarItemActionType.Navigation,
      action: '/account/deposit',
      isSelected$: this.isDepositPageVisible$,
      showLoggedIn: true,
      showLoggedOut: false,
    },
    {
      id: 4,
      icon: 'register',
      title: 'Join',
      type: NavBarItemActionType.Navigation,
      action: this.appConfigService.get('registrationRedirect') || '/register',
      isSelected$: this.isRegisterPageVisible$,
      showLoggedIn: false,
      showLoggedOut: true,
    },
  ];

  private readonly VIRTUALS_INSTANT_NAV_ITEMS = [
    {
      id: 0,
      icon: 'hamburger',
      title: 'Menu',
      type: this.enableSlideUps ? NavBarItemActionType.FunctionCall : NavBarItemActionType.Navigation,
      action: this.enableSlideUps ? 'showMenu' : '/menu',
      isSelected$: this.enableSlideUps ? this.applicationQuery.showMenu$ : this.isMenuPageVisible$,
      showLoggedIn: true,
      showLoggedOut: true,
    },
    {
      id: 1,
      icon: 'virtuals-lobby',
      title: 'Virtuals',
      type: NavBarItemActionType.FunctionCall,
      action: 'virtualsLobbyRedirect',
      isSelected$: this.isOnVirtualsLobby$,
      showLoggedIn: true,
      showLoggedOut: true,
    },
    {
      id: 2,
      icon: 'v-betslip',
      title: 'Betslip',
      type: this.enableSlideUps ? NavBarItemActionType.FunctionCall : NavBarItemActionType.Navigation,
      action: '/virtual/instant-coupon',
      badge$: this.instantBetSlipBadgeCount$,
      couponDataLength$: this.instantBetSlipBadgeCount$,
      isSelected$: this.applicationQuery.inCoupon$,
      showLoggedIn: true,
      showLoggedOut: true,
    },
    {
      id: 3,
      icon: 'v-mybets',
      title: 'My Bets',
      type: this.enableSlideUps ? NavBarItemActionType.FunctionCall : NavBarItemActionType.Navigation,
      action: this.enableSlideUps ? 'showMyBets' : '/my-bets/virtuals',
      badge$: this.virtualsMyBetsBadgeCount$,
      isSelected$: this.enableSlideUps ? this.applicationQuery.showMyBets$ : this.isMyBetsPageVisible$,
      showLoggedIn: true,
      showLoggedOut: false,
    },
    {
      id: 3,
      icon: 'v-tutorials',
      title: 'Tutorials',
      type: NavBarItemActionType.Navigation,
      action: '/help/betking-virtual-leagues-help',
      isSelected$: this.isVirtualsTutorialsPageVisible$,
      showLoggedIn: false,
      showLoggedOut: true,
    },
    {
      id: 4,
      icon: 'deposit',
      title: 'Deposit',
      type: NavBarItemActionType.Navigation,
      action: '/account/deposit',
      isSelected$: this.isDepositPageVisible$,
      showLoggedIn: true,
      showLoggedOut: false,
    },
    {
      id: 4,
      icon: 'register',
      title: 'Join',
      type: NavBarItemActionType.Navigation,
      action: this.appConfigService.get('registrationRedirect') || '/register',
      isSelected$: this.isRegisterPageVisible$,
      showLoggedIn: false,
      showLoggedOut: true,
    },
  ];

  // Nav bar Items
  // Type FunctionCall = call the same named function in the component
  // Type Navigation = navigates for the given url
  navBarItems$: BehaviorSubject<NavBarItemModel[]> = new BehaviorSubject(this.STANDARD_NAV_ITEMS);
  coupon$: BehaviorSubject<CouponQuery | VirtualsCouponQuery> = new BehaviorSubject(this.couponQuery);

  private readonly destroy$ = new Subject<any>();

  constructor(
    readonly accountQuery: AccountQuery,
    readonly accountStore: AccountStore,
    readonly applicationService: ApplicationService,
    readonly couponQuery: CouponQuery,
    readonly virtualsCouponQuery: VirtualsCouponQuery,
    readonly instantCouponQuery: InstantCouponQuery,
    private readonly appConfigService: AppConfigService,
    private readonly applicationQuery: ApplicationQuery,
    private readonly myBetsQuery: MyBetsQuery,
    private readonly router: Router,
    private readonly dataLayerService: DataLayerService
  ) {}

  ngOnInit(): void {
    combineLatest([this.applicationQuery.activeProduct$, this.applicationQuery.activeVirtualsLeague$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([activeProduct, activeVirtualsLeague]) => {
        switch (activeProduct) {
          case ProductType.SportsBook:
            this.navBarItems$.next(this.STANDARD_NAV_ITEMS);
            this.coupon$.next(this.couponQuery);
            break;
          case ProductType.Virtuals:
            if (activeVirtualsLeague === VirtualsLeagueType.Instant) {
              this.navBarItems$.next(this.VIRTUALS_INSTANT_NAV_ITEMS);
            } else {
              this.navBarItems$.next(this.VIRTUALS_SCHEDULED_NAV_ITEMS);
              this.coupon$.next(this.virtualsCouponQuery);
            }
            break;
          default:
            break;
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private createDataLayerEvent(): void {
    this.dataLayerService.createDataLayerEvent({
      event: DepositActions.DepositHomeNavBarClicked,
      user_id: this.accountQuery.userData?.id,
    });
  }

  navbarEvent(navBarItem: NavBarItemModel): void {
    if (navBarItem.title === 'Deposit') {
      this.createDataLayerEvent();
    }
    if (!navBarItem.action) {
      return;
    }
    if (navBarItem.type === NavBarItemActionType.Navigation) {
      if (this.router.url !== navBarItem.action && this.router.url.indexOf(navBarItem.action) === -1) {
        if (navBarItem.action.includes('register')) {
          window.location.href = '/account/registration';
        } else if (navBarItem.action.includes('/my-bets/sports') && this.sportsMyBetsUrl) {
          window.location.href = this.sportsMyBetsUrl;
        } else this.router.navigate([navBarItem.action]);
        this.applicationService.closeAnySlideUps();
      } else {
        window.history.go(-1);
      }
    } else {
      this.applicationService[navBarItem.action]();
    }
  }

  navBarItemTrackBy(index: number, item: NavBarItemModel): string {
    return item.title;
  }
}
