import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { isEmpty } from 'lodash-es';
import { EMPTY, forkJoin, Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { StatisticsQuery } from 'src/app/core/state/statistics/statistics.query';
import { StatisticsStore } from 'src/app/core/state/statistics/statistics.store';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import {
  AssistsModel,
  CardsModel,
  GoalscorerModel,
  HeadToHeadModel,
  LastMatchesModel,
  LeagueRankingModel,
  LeagueRankingTeamModel,
  MatchModel,
  MatchStatisticsModel,
  NextMatchesModel,
  OverUnderModel,
  OverUnderTeamModel,
  OverUnderTypeModel,
  PlayerModel,
  WinProbabilityModel,
} from 'src/app/shared/models/statistics.model';
import { LanguageService } from 'src/app/core/services/language.service';
import { MatchQuery } from 'src/app/modules/sport/state/match-view/match.query';
import { ProviderType } from 'src/app/shared/models/sport.model';
import { APIService } from './api.service';
import { AppConfigService } from './app-config.service';
import { animationDuration } from './variables.service';

@Injectable({
  providedIn: 'root',
})
export class StatisticsService {
  private readonly renderer: Renderer2;

  constructor(
    private readonly apiService: APIService,
    private readonly statisticsQuery: StatisticsQuery,
    private readonly statisticsStore: StatisticsStore,
    private readonly appConfigService: AppConfigService,
    private readonly rendererFactory: RendererFactory2,
    private readonly languageService: LanguageService,
    private readonly matchQuery: MatchQuery
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  getMatchStatisticTabs() {
    if (
      this.statisticsQuery.getValue()?.preMatchListStatisticsTabs &&
      this.statisticsQuery.getValue()?.preMatchListStatisticsTabs &&
      this.statisticsQuery.getValue()?.liveListStatisticsTabs &&
      this.statisticsQuery.getValue()?.liveEventStatisticsTabs
    )
      return EMPTY;
    const url = `sportsbook-page?locale=${this.languageService.strapiCMSLocale}&populate[liveStatstics][populate]=*&populate[prematchStatstics][populate]=*`;
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });
    return this.apiService.get(APIType.StrapiCms, url, apiSettings).pipe(
      map(res => {
        const statistics = res.data.attributes;
        this.statisticsStore.updateStatisticTabs(
          statistics.liveStatstics?.list.filter(t => t.enabled),
          statistics.liveStatstics?.event.filter(t => t.enabled),
          statistics.prematchStatstics?.list.filter(t => t.enabled),
          statistics.prematchStatstics?.event.filter(t => t.enabled),
          statistics.liveStatstics?.enabled,
          statistics.prematchStatstics?.enabled
        );
      })
    );
  }

  addCppStatWidgets(tab: string, fixtureId: string) {
    this.removeCppStatWidget();
    if (typeof window !== 'undefined') {
      const script = this.renderer.createElement('script');
      this.renderer.setAttribute(script, 'src', this.appConfigService.get('apiBaseUrl').cppWidget);
      this.renderer.setAttribute(script, 'data-uuid', fixtureId);
      this.renderer.setAttribute(script, 'data-outletkey', this.appConfigService.get('sports').statistics.cppOutletKey);
      this.renderer.setAttribute(script, 'data-redirectto', tab || '');
      this.renderer.setAttribute(script, 'data-hideHeader', 'true');
      this.renderer.setAttribute(script, 'data-hideTabs', 'true');
      this.renderer.setAttribute(script, 'data-hidePrimaryTabs', 'true');
      this.renderer.setAttribute(script, 'containername', 'cppContainer');
      this.renderer.setAttribute(script, 'id', 'cppWidget');
      document.body.appendChild(script);
    }
  }

  removeCppStatWidget() {
    const iframe = document.getElementById('contentPlayerProIframe');
    const script = document.getElementById('cppWidget');
    if (iframe && script) {
      iframe.parentNode.removeChild(iframe);
      script.parentNode.removeChild(script);
    }
  }

  statisticsBetRadar(eventId: number): void {
    if (!this.appConfigService.get('sports').statistics.enabled && (eventId === null || eventId === 0)) {
      return;
    }

    if (this.appConfigService.get('sports').statistics.type === 1) {
      // Open popup stats
      const externalId = this.matchQuery.fixtureInfo[0]?.sources
        ?.find(source => source.sourceId === ProviderType.BetRadar.toString())
        ?.providerId?.replace(/\D/g, '');
      this.statisticsBetRadarPopup(parseInt(externalId, 10));
    } else if (this.appConfigService.get('sports').statistics.type === 2) {
      // Open inline stats
      this.updateSelectedEventId(eventId);
    }

    if (!this.statisticsQuery.expandedStatistics) {
      this.removeCppStatWidget();
    }
  }

  statisticsBetRadarPopup(externalId: number, language: string = 'en'): void {
    let url = this.appConfigService.get('apiBaseUrl').betRadarStatistics;

    if (url.endsWith('/')) {
      url = url.slice(0, -1);
    }

    window.open(`${url}/${language}/match/${externalId}`, '_blank');
  }

  updateExpandedStatistics(expandedStatistics: boolean): void {
    this.statisticsStore.updateUI({ expandedStatistics });
  }

  updateSelectedEventId(currentEventId: number): void {
    const selectedEventId = this.statisticsQuery.selectedEventId;

    if (currentEventId !== selectedEventId) {
      this.statisticsStore.clearStatisticsData();
      this.statisticsStore.updateSelectedEventId(currentEventId);
    } else {
      this.updateExpandedStatistics(false);
      setTimeout(() => {
        this.statisticsStore.clearStatisticsData();
      }, animationDuration);
    }
  }

  clearStatisticsData(): void {
    this.statisticsStore.clearStatisticsData();
  }

  getMatchStatistics(matchId: number, language: string = 'en'): Observable<boolean> {
    if (!matchId) {
      return new Observable<false>();
    }

    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });

    return this.apiService.get<any>(APIType.BetRadarResults, `${language}/gismo/stats_match_get/${matchId}`, apiSettings).pipe(
      map(responseData => {
        if (!responseData) {
          return false;
        }

        const matchResult = responseData.doc[0];

        const matchStatistics = new MatchStatisticsModel({
          seasonId: matchResult.data._seasonid,
          homeTeamId: matchResult.data.teams.home.uid,
          awayTeamId: matchResult.data.teams.away.uid,
        });

        this.statisticsStore.updateMatchStatisticsData(matchStatistics);
        return true;
      })
    );
  }

  getStatistics(homeTeamId: number, awayTeamId: number, seasonId: number, language: string = 'en'): Observable<void> {
    if (!homeTeamId || !awayTeamId || !seasonId) {
      return;
    }

    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });

    return forkJoin(
      this.apiService.get<any>(APIType.BetRadarResults, `${language}/gismo/stats_team_versus/${homeTeamId}/${awayTeamId}`, apiSettings),
      this.apiService.get<any>(APIType.BetRadarResults, `${language}/gismo/stats_team_lastx/${homeTeamId}`, apiSettings),
      this.apiService.get<any>(APIType.BetRadarResults, `${language}/gismo/stats_team_lastx/${awayTeamId}`, apiSettings),
      this.apiService.get<any>(APIType.BetRadarResults, `${language}/gismo/stats_team_nextx/${homeTeamId}`, apiSettings),
      this.apiService.get<any>(APIType.BetRadarResults, `${language}/gismo/stats_team_nextx/${awayTeamId}`, apiSettings),
      this.apiService.get<any>(APIType.BetRadarResults, `${language}/gismo/stats_season_overunder/${seasonId}`, apiSettings),
      this.apiService.get<any>(APIType.BetRadarResults, `${language}/gismo/stats_season_topgoals/${seasonId}`, apiSettings),
      this.apiService.get<any>(APIType.BetRadarResults, `${language}/gismo/stats_season_topassists/${seasonId}`, apiSettings),
      this.apiService.get<any>(APIType.BetRadarResults, `${language}/gismo/stats_season_topcards/${seasonId}`, apiSettings),
      this.apiService.get<any>(APIType.BetRadarResults, `${language}/Europe:Berlin/gismo/stats_season_tables/${seasonId}`, apiSettings)
    ).pipe(
      map(
        ([
          headToHeadData,
          homeLastMatchesData,
          awayLastMatchesData,
          homeNextMatchesData,
          awayNextMatchesData,
          overUnderData,
          goalscorerData,
          assistaData,
          cardsData,
          leagueRankingData,
        ]) => {
          // Head to Head
          this.parseHeadToHeadData(headToHeadData, homeTeamId, awayTeamId);

          // Last Matches
          this.parseLastMatchesData(homeLastMatchesData, awayLastMatchesData);

          // Next Matches
          this.parseNextMatchesData(homeNextMatchesData, awayNextMatchesData);

          // Over / Under
          this.parseOverUnderData(overUnderData, homeTeamId, awayTeamId);

          // Goalscorer
          this.parseGoalscorerData(goalscorerData, homeTeamId, awayTeamId);

          // Assists
          this.parseAssistsData(assistaData, homeTeamId, awayTeamId);

          // Cards
          this.parseCardsData(cardsData, homeTeamId, awayTeamId);

          // League Ranking
          this.parseLeagueRankingData(leagueRankingData, homeTeamId, awayTeamId);
        }
      )
    );
  }

  getVSLMatchDetail(matchId: number) {
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });
    return this.apiService.get(APIType.SportsbookVslFeed, `api/v1/matchDetail/${matchId}`, apiSettings).pipe(
      tap((responseData: any) => {
        return responseData;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  getVSLTeamStatics(apiSettings: any, teamId: number) {
    return this.apiService.get(APIType.SportsbookVslFeed, `api/v1/teamgames/${teamId}`, apiSettings).pipe(
      tap((responseData: any) => {
        return responseData;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  getVSLHeadToHead(apiSettings: any, homeId: number, awayId: number) {
    return this.apiService.get(APIType.SportsbookVslFeed, `api/v1/headToHead/${homeId}/${awayId}`, apiSettings).pipe(
      tap((responseData: any) => {
        return responseData;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  getVSLStatistics(homeTeam: number, awayTeam: number) {
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });
    this.apiService.get(APIType.SportsbookVslFeed, `api/v1/teamgames/${homeTeam}`, apiSettings).pipe(
      tap((responseData: any) => {
        return responseData;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
    const homeTeam$ = this.getVSLTeamStatics(apiSettings, homeTeam);
    const awayTeam$ = this.getVSLTeamStatics(apiSettings, awayTeam);
    const headToHead$ = this.getVSLHeadToHead(apiSettings, homeTeam, awayTeam);
    return forkJoin([homeTeam$, awayTeam$, headToHead$]).subscribe(results => {
      const headToHead = results[2];
      const homeData = results[0];
      const awayData = results[1];
      this.parseHeadToHeadVSLData(headToHead, homeTeam, awayTeam);
      this.parseLastMatchesVSLData(homeData, awayData);
      this.parseNextMatchesVSLData(homeData, awayData);
      return results;
    });
  }

  // Parse Head to Head
  parseHeadToHeadData(data: any, homeTeamId: number, awayTeamId: number): void {
    if (data && !isEmpty(data.doc[0].data.matches)) {
      const h2hResult = data.doc[0].data;

      try {
        const homeTeamName = h2hResult.teams[homeTeamId].mediumname;
        const awayTeamName = h2hResult.teams[awayTeamId].mediumname;

        const headToHeadHistory: MatchModel[] = [];
        let teamHomeWins = 0;
        let teamDraws = 0;
        let teamAwayWins = 0;

        let count = 0;
        h2hResult.matches.forEach(match => {
          count++;

          if (match.result.winner === 'home') {
            if (match.teams.home.uid === homeTeamId) {
              teamHomeWins++;
            } else {
              teamAwayWins++;
            }
          } else if (match.result.winner === 'away') {
            if (match.teams.away.uid === awayTeamId) {
              teamAwayWins++;
            } else {
              teamHomeWins++;
            }
          } else {
            teamDraws++;
          }

          if (count <= this.appConfigService.get('sports').statistics.maximumRows) {
            let round = '';
            if (match.roundname && match.roundname.shortname !== undefined && match.roundname.shortname !== '') {
              round = `${h2hResult.tournaments[match._tid].abbr} ${match.roundname.shortname}`;
            } else if (match.roundname && match.roundname.name !== undefined && match.roundname.name !== '') {
              round = `${h2hResult.tournaments[match._tid].abbr} ${match.roundname.name}`;
            } else {
              round = h2hResult.tournaments[match._tid].abbr;
            }

            const objMatch = new MatchModel({
              date: match.time.date,
              teamHomeId: match.teams.home.uid,
              teamHomeName: match.teams.home.abbr,
              teamHomeScore: match.result.home !== undefined ? match.result.home : '-',
              teamHomeLogo: `${this.appConfigService.get('apiBaseUrl').betRadarTeamLogo}/crest/small/${match.teams.home.uid}.png`,
              teamAwayId: match.teams.away.uid,
              teamAwayName: match.teams.away.abbr,
              teamAwayScore: match.result.away !== undefined ? match.result.away : '-',
              teamAwayLogo: `${this.appConfigService.get('apiBaseUrl').betRadarTeamLogo}/crest/small/${match.teams.away.uid}.png`,
              winner: match.result.winner || 'draw',
              round: round,
            });

            headToHeadHistory.push(objMatch);
          }
        });

        const totalMatches = teamHomeWins + teamDraws + teamAwayWins;

        const teamHomeWinsPerc = ((teamHomeWins / totalMatches) * 100).toFixed(0);
        const teamDrawsPerc = ((teamDraws / totalMatches) * 100).toFixed(0);
        const teamAwayWinsPerc = ((teamAwayWins / totalMatches) * 100).toFixed(0);

        const headToHead = new HeadToHeadModel({
          homeTeamId: homeTeamId,
          homeTeamName: homeTeamName,
          awayTeamId: awayTeamId,
          awayTeamName: awayTeamName,
          headToHeadHistory: headToHeadHistory,
          teamHomeWins: teamHomeWins,
          teamDraws: teamDraws,
          teamAwayWins: teamAwayWins,
          teamHomeWinsPerc: teamHomeWinsPerc,
          teamDrawsPerc: teamDrawsPerc,
          teamAwayWinsPerc: teamAwayWinsPerc,
        });

        this.statisticsStore.updateHeadToHeadData(headToHead);
      } catch (e) {
        // error
      }
    }
  }

  parseHeadToHeadVSLData(data: any, homeTeamId: number, awayTeamId: number): void {
    if (data) {
      try {
        const homeTeamNameG = data.team1.name;
        const awayTeamNameG = data.team2.name;

        const headToHeadHistory: MatchModel[] = [];
        let teamHomeWins = 0;
        let teamDraws = 0;
        let teamAwayWins = 0;

        let count = 0;
        data.previousGames.forEach(match => {
          count++;
          if (match.gameResult === 'Home Win') {
            teamHomeWins++;
          } else if (match.gameResult === 'Away Win') {
            teamAwayWins++;
          } else if (match.gameResult === 'Draw') {
            teamDraws++;
          }

          if (count <= this.appConfigService.get('sports').statistics.maximumRows) {
            const objMatch = new MatchModel({
              date: new Date(match.eventStartTime).toLocaleDateString('en-uk'),
              teamHomeId: match.homeTeamId,
              teamHomeName: data.team1.name,
              teamHomeScore: match.homeGoals !== undefined ? match.homeGoals : '-',
              teamAwayId: match.awayTeamId,
              teamAwayName: data.team2.name,
              teamAwayScore: match.awayGoals !== undefined ? match.awayGoals : '-',
              winner: match.gameResult === 'Home Win' ? 'home' : match.gameResult === 'Away Win' ? 'away' : 'draw',
            });

            headToHeadHistory.push(objMatch);
          }
        });

        const totalMatches = teamHomeWins + teamDraws + teamAwayWins;

        const teamHomeWinsPerc = ((teamHomeWins / totalMatches) * 100).toFixed(0);
        const teamDrawsPerc = ((teamDraws / totalMatches) * 100).toFixed(0);
        const teamAwayWinsPerc = ((teamAwayWins / totalMatches) * 100).toFixed(0);

        const headToHead = new HeadToHeadModel({
          homeTeamId: homeTeamId,
          homeTeamName: homeTeamNameG,
          awayTeamId: awayTeamId,
          awayTeamName: awayTeamNameG,
          headToHeadHistory: headToHeadHistory,
          teamHomeWins: teamHomeWins,
          teamDraws: teamDraws,
          teamAwayWins: teamAwayWins,
          teamHomeWinsPerc: teamHomeWinsPerc,
          teamDrawsPerc: teamDrawsPerc,
          teamAwayWinsPerc: teamAwayWinsPerc,
        });
        this.parseWinProbability(data?.futureGames[0]?.winProbabilities);
        this.statisticsStore.updateHeadToHeadData(headToHead);
      } catch (e) {
        // error
      }
    }
  }

  parseWinProbability(winProbabilities: any): void {
    const winProbability = new WinProbabilityModel({
      awayWinProbability: (winProbabilities.awayWinProbability * 100).toFixed(0),
      drawProbability: (winProbabilities.drawProbability * 100).toFixed(0),
      homeTeamProbability: (winProbabilities.homeTeamProbability * 100).toFixed(0),
    });
    this.statisticsStore.updateWinProbabilityData(winProbability);
  }

  // Parse Last Matches
  parseLastMatchesData(homeData: any, awayData: any): void {
    if (
      homeData &&
      homeData.doc[0].data &&
      !isEmpty(homeData.doc[0].data.matches) &&
      awayData &&
      awayData.doc[0].data &&
      !isEmpty(awayData.doc[0].data.matches)
    ) {
      const homeResult = homeData.doc[0].data;
      const awayResult = awayData.doc[0].data;

      try {
        const homeTeamId = homeResult.team._id;
        const awayTeamId = awayResult.team._id;
        const homeTeamName = homeResult.team.mediumname;
        const awayTeamName = awayResult.team.mediumname;

        const homeLastResults = [];
        const awayLastResults = [];
        const homeMatches = [];
        const awayMatches = [];

        // Home Last Matches
        let count = 0;
        homeResult.matches.forEach(match => {
          count++;

          if (count - 1 <= this.appConfigService.get('sports').statistics.maximumRows) {
            let currTeamPosition = 'home';
            if (homeTeamId === match.teams.away.uid) {
              currTeamPosition = 'away';
            }

            // the count below is used to avoid dublicates, this count is being removed from html
            if (!match.result.winner) {
              homeLastResults.push({ id: count, result: 'D' }); // Draw
            } else if (match.result.winner === currTeamPosition) {
              homeLastResults.push({ id: count, result: 'W' }); // Won
            } else {
              homeLastResults.push({ id: count, result: 'L' }); // Lost
            }

            if (count <= this.appConfigService.get('sports').statistics.maximumRows) {
              let round = '';
              if (match.roundname && match.roundname.shortname !== undefined && match.roundname.shortname !== '') {
                round = `${homeResult.tournaments[match._tid].abbr} ${match.roundname.shortname}`;
              } else if (match.roundname && match.roundname.name !== undefined && match.roundname.name !== '') {
                round = `${homeResult.tournaments[match._tid].abbr} ${match.roundname.name}`;
              } else {
                round = homeResult.tournaments[match._tid].abbr;
              }

              const objMatch = new MatchModel({
                date: match.time.date,
                teamHomeId: match.teams.home.uid,
                teamHomeName: match.teams.home.abbr,
                teamHomeScore: match.result.home !== undefined ? match.result.home : '-',
                teamHomeLogo: `${this.appConfigService.get('apiBaseUrl').betRadarTeamLogo}/crest/small/${match.teams.home.uid}.png`,
                teamAwayId: match.teams.away.uid,
                teamAwayName: match.teams.away.abbr,
                teamAwayScore: match.result.away !== undefined ? match.result.away : '-',
                teamAwayLogo: `${this.appConfigService.get('apiBaseUrl').betRadarTeamLogo}/crest/small/${match.teams.away.uid}.png`,
                winner: match.result.winner || 'draw',
                round: round,
              });

              homeMatches.push(objMatch);
            }
          }
        });

        // Away Last Matches
        count = 0;
        awayResult.matches.forEach(match => {
          count++;

          if (count - 1 <= this.appConfigService.get('sports').statistics.maximumRows) {
            let currTeamPosition = 'home';
            if (awayTeamId === match.teams.away.uid) {
              currTeamPosition = 'away';
            }

            // the count below is used to avoid dublicates, this count is being removed from html
            if (!match.result.winner) {
              awayLastResults.push({ id: count, result: 'D' }); // Draw
            } else if (match.result.winner === currTeamPosition) {
              awayLastResults.push({ id: count, result: 'W' }); // Won
            } else {
              awayLastResults.push({ id: count, result: 'L' }); // Lost
            }

            if (count <= this.appConfigService.get('sports').statistics.maximumRows) {
              let round = '';
              if (match.roundname && match.roundname.shortname !== undefined && match.roundname.shortname !== '') {
                round = `${awayResult.tournaments[match._tid].abbr} ${match.roundname.shortname}`;
              } else if (match.roundname && match.roundname.name !== undefined && match.roundname.name !== '') {
                round = `${awayResult.tournaments[match._tid].abbr} ${match.roundname.name}`;
              } else {
                round = awayResult.tournaments[match._tid].abbr;
              }

              const objMatch = new MatchModel({
                date: match.time.date,
                teamHomeId: match.teams.home.uid,
                teamHomeName: match.teams.home.abbr,
                teamHomeScore: match.result.home !== undefined ? match.result.home : '-',
                teamHomeLogo: `${this.appConfigService.get('apiBaseUrl').betRadarTeamLogo}/crest/small/${match.teams.home.uid}.png`,
                teamAwayId: match.teams.away.uid,
                teamAwayName: match.teams.away.abbr,
                teamAwayScore: match.result.away !== undefined ? match.result.away : '-',
                teamAwayLogo: `${this.appConfigService.get('apiBaseUrl').betRadarTeamLogo}/crest/small/${match.teams.away.uid}.png`,
                winner: match.result.winner || 'draw',
                round: round,
              });

              awayMatches.push(objMatch);
            }
          }
        });

        const lastMatches = new LastMatchesModel({
          homeTeamId: homeTeamId,
          homeTeamName: homeTeamName,
          awayTeamId: awayTeamId,
          awayTeamName: awayTeamName,
          homeLastResults: homeLastResults,
          awayLastResults: awayLastResults,
          homeMatches: homeMatches,
          awayMatches: awayMatches,
        });

        this.statisticsStore.updateLastMatchesData(lastMatches);
      } catch (e) {
        // error
      }
    }
  }

  parseLastMatchesVSLData(homeData: any, awayData: any): void {
    if (
      homeData &&
      homeData.previousGames.length &&
      !isEmpty(homeData) &&
      awayData &&
      awayData.previousGames.length &&
      !isEmpty(awayData)
    ) {
      try {
        const homeTeamId = homeData.thisTeamId;
        const homeTeamName = homeData.thisTeamName;
        const awayTeamId = awayData.thisTeamId;
        const awayTeamName = awayData.thisTeamName;

        const homeLastResults = [];
        const awayLastResults = [];
        const homeMatches = [];
        const awayMatches = [];

        // Home Last Matches
        let count = 0;
        homeData.previousGames.forEach(match => {
          count++;

          if (count - 1 <= this.appConfigService.get('sports').statistics.maximumRows) {
            // the count below is used to avoid dublicates, this count is being removed from html
            if (match.result === 'draw') {
              homeLastResults.push({ id: count, result: 'D' }); // Draw
            } else if (match.result === 'win') {
              homeLastResults.push({ id: count, result: 'W' }); // Won
            } else {
              homeLastResults.push({ id: count, result: 'L' }); // Lost
            }

            if (count <= this.appConfigService.get('sports').statistics.maximumRows) {
              const objMatch = new MatchModel({
                date: new Date(match.eventStartTime).toLocaleDateString('en-uk'),
                teamHomeId: homeTeamId,
                teamHomeName: homeTeamName,
                teamHomeScore: match.thisTeamsGoals !== undefined ? match.thisTeamsGoals : '-',
                teamAwayId: match.otherTeamId,
                teamAwayName: match.otherTeamName,
                teamAwayScore: match.otherTeamsGoals !== undefined ? match.otherTeamsGoals : '-',
                winner: match.homeOrAway || 'draw',
              });

              homeMatches.push(objMatch);
            }
          }
        });

        // Away Last Matches
        count = 0;
        awayData.previousGames.forEach(match => {
          count++;

          if (count - 1 <= this.appConfigService.get('sports').statistics.maximumRows) {
            // the count below is used to avoid dublicates, this count is being removed from html
            if (match.result === 'draw') {
              awayLastResults.push({ id: count, result: 'D' }); // Draw
            } else if (match.result === 'win') {
              awayLastResults.push({ id: count, result: 'W' }); // Won
            } else {
              awayLastResults.push({ id: count, result: 'L' }); // Lost
            }

            if (count <= this.appConfigService.get('sports').statistics.maximumRows) {
              const objMatch = new MatchModel({
                date: new Date(match.eventStartTime).toLocaleDateString('en-uk'),
                teamHomeId: match.otherTeamId,
                teamHomeName: match.otherTeamName,
                teamHomeScore: match.thisTeamsGoals !== undefined ? match.thisTeamsGoals : '-',
                teamAwayId: awayTeamId,
                teamAwayName: awayTeamName,
                teamAwayScore: match.otherTeamsGoals !== undefined ? match.otherTeamsGoals : '-',
                winner: match.homeOrAway || 'draw',
              });
              awayMatches.push(objMatch);
            }
          }
        });

        const lastMatches = new LastMatchesModel({
          homeTeamId: homeTeamId,
          homeTeamName: homeTeamName,
          awayTeamId: awayTeamId,
          awayTeamName: awayTeamName,
          homeLastResults: homeLastResults,
          awayLastResults: awayLastResults,
          homeMatches: homeMatches,
          awayMatches: awayMatches,
        });
        this.statisticsStore.updateLastMatchesData(lastMatches);
      } catch (e) {
        // error
      }
    }
  }

  // Parse Next Matches
  parseNextMatchesData(homeData: any, awayData: any): void {
    if (
      homeData &&
      homeData.doc[0].data &&
      !isEmpty(homeData.doc[0].data.matches) &&
      awayData &&
      awayData.doc[0].data &&
      !isEmpty(awayData.doc[0].data.matches)
    ) {
      const homeResult = homeData.doc[0].data;
      const awayResult = awayData.doc[0].data;

      try {
        const homeTeamId = homeResult.team._id;
        const awayTeamId = awayResult.team._id;
        const homeTeamName = homeResult.team.mediumname;
        const awayTeamName = awayResult.team.mediumname;

        const homeMatches = [];
        const awayMatches = [];

        // Home Last Matches
        let count = 0;
        homeResult.matches.forEach(match => {
          count++;

          if (count <= this.appConfigService.get('sports').statistics.maximumRows) {
            let round = '';
            if (match.roundname && match.roundname.shortname !== undefined && match.roundname.shortname !== '') {
              round = `${homeResult.tournaments[match._tid].abbr} ${match.roundname.shortname}`;
            } else if (match.roundname && match.roundname.name !== undefined && match.roundname.name !== '') {
              round = `${homeResult.tournaments[match._tid].abbr} ${match.roundname.name}`;
            } else {
              round = homeResult.tournaments[match._tid].abbr;
            }

            const objMatch = new MatchModel({
              date: match.time.date,
              teamHomeId: match.teams.home.uid,
              teamHomeName: match.teams.home.abbr,
              teamHomeScore: match.result.home !== undefined ? match.result.home : '-',
              teamHomeLogo: `${this.appConfigService.get('apiBaseUrl').betRadarTeamLogo}/crest/small/${match.teams.home.uid}.png`,
              teamAwayId: match.teams.away.uid,
              teamAwayName: match.teams.away.abbr,
              teamAwayScore: match.result.away !== undefined ? match.result.away : '-',
              teamAwayLogo: `${this.appConfigService.get('apiBaseUrl').betRadarTeamLogo}/crest/small/${match.teams.away.uid}.png`,
              winner: match.result.winner || 'draw',
              round: round,
            });

            homeMatches.push(objMatch);
          }
        });

        // Away Last Matches
        count = 0;
        awayResult.matches.forEach(match => {
          count++;

          if (count <= this.appConfigService.get('sports').statistics.maximumRows) {
            let round = '';
            if (match.roundname && match.roundname.shortname !== undefined && match.roundname.shortname !== '') {
              round = `${awayResult.tournaments[match._tid].abbr} ${match.roundname.shortname}`;
            } else if (match.roundname && match.roundname.name !== undefined && match.roundname.name !== '') {
              round = `${awayResult.tournaments[match._tid].abbr} ${match.roundname.name}`;
            } else {
              round = awayResult.tournaments[match._tid].abbr;
            }

            const objMatch = new MatchModel({
              date: match.time.date,
              teamHomeId: match.teams.home.uid,
              teamHomeName: match.teams.home.abbr,
              teamHomeScore: match.result.home !== undefined ? match.result.home : '-',
              teamHomeLogo: `${this.appConfigService.get('apiBaseUrl').betRadarTeamLogo}/crest/small/${match.teams.home.uid}.png`,
              teamAwayId: match.teams.away.uid,
              teamAwayName: match.teams.away.abbr,
              teamAwayScore: match.result.away !== undefined ? match.result.away : '-',
              teamAwayLogo: `${this.appConfigService.get('apiBaseUrl').betRadarTeamLogo}/crest/small/${match.teams.away.uid}.png`,
              winner: match.result.winner || 'draw',
              round: round,
            });

            awayMatches.push(objMatch);
          }
        });

        const nextMatches = new NextMatchesModel({
          homeTeamId: homeTeamId,
          homeTeamName: homeTeamName,
          awayTeamId: awayTeamId,
          awayTeamName: awayTeamName,
          homeMatches: homeMatches,
          awayMatches: awayMatches,
        });

        this.statisticsStore.updateNextMatchesData(nextMatches);
      } catch (e) {
        // error
      }
    }
  }

  parseNextMatchesVSLData(homeData: any, awayData: any): void {
    if (homeData && homeData.futureGames.length && !isEmpty(homeData) && awayData && awayData.futureGames.length && !isEmpty(awayData)) {
      try {
        const homeTeamId = homeData.thisTeamId;
        const awayTeamId = awayData.thisTeamId;
        const homeTeamName = homeData.thisTeamName;
        const awayTeamName = awayData.thisTeamName;

        const homeMatches = [];
        const awayMatches = [];

        // Home Future Matches
        let count = 0;
        homeData.futureGames.forEach(match => {
          count++;

          if (count <= this.appConfigService.get('sports').statistics.maximumRows) {
            const objMatch = new MatchModel({
              date: new Date(match.eventStartTime).toLocaleDateString('en-uk'),
              teamHomeId: homeTeamId,
              teamHomeName: homeTeamName,
              teamAwayId: match.otherTeamId,
              teamAwayName: match.otherTeamName,
            });

            homeMatches.push(objMatch);
          }
        });

        // Away Future Matches
        count = 0;
        awayData.futureGames.forEach(match => {
          count++;

          if (count <= this.appConfigService.get('sports').statistics.maximumRows) {
            const objMatch = new MatchModel({
              date: new Date(match.eventStartTime).toLocaleDateString('en-uk'),
              teamHomeId: match.otherTeamId,
              teamHomeName: match.otherTeamName,
              teamAwayId: awayTeamId,
              teamAwayName: awayTeamName,
            });

            awayMatches.push(objMatch);
          }
        });

        const nextMatches = new NextMatchesModel({
          homeTeamId: homeTeamId,
          homeTeamName: homeTeamName,
          awayTeamId: awayTeamId,
          awayTeamName: awayTeamName,
          homeMatches: homeMatches,
          awayMatches: awayMatches,
        });

        this.statisticsStore.updateNextMatchesData(nextMatches);
      } catch (e) {
        // error
      }
    }
  }

  // Parse Over / Under
  parseOverUnderData(data: any, homeTeamId: number, awayTeamId: number): void {
    if (
      data &&
      data.doc[0].data.stats &&
      data.doc[0].data.stats[homeTeamId] &&
      data.doc[0].data.stats[homeTeamId].total &&
      data.doc[0].data.stats[awayTeamId] &&
      data.doc[0].data.stats[awayTeamId].total
    ) {
      const result = data.doc[0].data;

      try {
        const homeStats = result.stats[homeTeamId];
        const awayStats = result.stats[awayTeamId];

        const allfulltime = [];
        const all1sthalf = [];
        const all2ndhalf = [];
        const homefulltime = [];
        const home1sthalf = [];
        const home2ndhalf = [];
        const awayfulltime = [];
        const away1sthalf = [];
        const away2ndhalf = [];

        // All Matches Full Time
        Object.keys(homeStats.total.ft).forEach(type => {
          const obj = new OverUnderTypeModel({
            id: type.replace('.', ''),
            spread: type,
            teams: [
              {
                teamId: homeStats.team._id,
                teamName: homeStats.team.abbr,
                teamLogo: `${this.appConfigService.get('apiBaseUrl').betRadarTeamLogo}/crest/small/${homeStats.team._id}.png`,
                played: homeStats.matches,
                over: homeStats.total.ft[type].over,
                under: homeStats.total.ft[type].under,
                averageGoalscored: homeStats.goalsscored.ft.average,
                cleanSheet: homeStats.conceded.ft.cleansheets,
                atLeastOneGoal: homeStats.goalsscored.ft.atleastonegoal,
                order: 1,
              },
              {
                teamId: awayStats.team._id,
                teamName: awayStats.team.abbr,
                teamLogo: `${this.appConfigService.get('apiBaseUrl').betRadarTeamLogo}/crest/small/${awayStats.team._id}.png`,
                played: awayStats.matches,
                over: awayStats.total.ft[type].over,
                under: awayStats.total.ft[type].under,
                averageGoalscored: awayStats.goalsscored.ft.average,
                cleanSheet: awayStats.conceded.ft.cleansheets,
                atLeastOneGoal: awayStats.goalsscored.ft.atleastonegoal,
                order: 2,
              },
            ],
          });

          allfulltime.push(obj);
        });
        // All Matches 1st Half
        if (homeStats.total.p1) {
          Object.keys(homeStats.total.p1).forEach(type => {
            const obj = new OverUnderTypeModel({
              id: type.replace('.', ''),
              spread: type,
              teams: [
                {
                  teamId: homeStats.team._id,
                  teamName: homeStats.team.abbr,
                  teamLogo: `${this.appConfigService.get('apiBaseUrl').betRadarTeamLogo}/crest/small/${homeStats.team._id}.png`,
                  played: homeStats.matches,
                  over: homeStats.total.p1[type].over,
                  under: homeStats.total.p1[type].under,
                  averageGoalscored: homeStats.goalsscored.p1.average,
                  cleanSheet: homeStats.conceded.p1.cleansheets,
                  atLeastOneGoal: homeStats.goalsscored.p1.atleastonegoal,
                  order: 1,
                },
                {
                  teamId: awayStats.team._id,
                  teamName: awayStats.team.abbr,
                  teamLogo: `${this.appConfigService.get('apiBaseUrl').betRadarTeamLogo}/crest/small/${awayStats.team._id}.png`,
                  played: awayStats.matches,
                  over: awayStats.total.p1[type].over,
                  under: awayStats.total.p1[type].under,
                  averageGoalscored: awayStats.goalsscored.p1.average,
                  cleanSheet: awayStats.conceded.p1.cleansheets,
                  atLeastOneGoal: awayStats.goalsscored.p1.atleastonegoal,
                  order: 2,
                },
              ],
            });

            all1sthalf.push(obj);
          });
        }
        // All Matches 2nd Half
        if (homeStats.total.p2) {
          Object.keys(homeStats.total.p2).forEach(type => {
            const obj = new OverUnderTypeModel({
              id: type.replace('.', ''),
              spread: type,
              teams: [
                {
                  teamId: homeStats.team._id,
                  teamName: homeStats.team.abbr,
                  teamLogo: `${this.appConfigService.get('apiBaseUrl').betRadarTeamLogo}/crest/small/${homeStats.team._id}.png`,
                  played: homeStats.matches,
                  over: homeStats.total.p2[type].over,
                  under: homeStats.total.p2[type].under,
                  averageGoalscored: homeStats.goalsscored.p2.average,
                  cleanSheet: homeStats.conceded.p2.cleansheets,
                  atLeastOneGoal: homeStats.goalsscored.p2.atleastonegoal,
                  order: 1,
                },
                {
                  teamId: awayStats.team._id,
                  teamName: awayStats.team.abbr,
                  teamLogo: `${this.appConfigService.get('apiBaseUrl').betRadarTeamLogo}/crest/small/${awayStats.team._id}.png`,
                  played: awayStats.matches,
                  over: awayStats.total.p2[type].over,
                  under: awayStats.total.p2[type].under,
                  averageGoalscored: awayStats.goalsscored.p2.average,
                  cleanSheet: awayStats.conceded.p2.cleansheets,
                  atLeastOneGoal: awayStats.goalsscored.p2.atleastonegoal,
                  order: 2,
                },
              ],
            });

            all2ndhalf.push(obj);
          });
        }

        // Home Matches Full Time
        Object.keys(homeStats.home.ft).forEach(type => {
          const obj = new OverUnderTypeModel({
            id: type.replace('.', ''),
            spread: type,
            teams: [
              {
                teamId: homeStats.team._id,
                teamName: homeStats.team.abbr,
                teamLogo: `${this.appConfigService.get('apiBaseUrl').betRadarTeamLogo}/crest/small/${homeStats.team._id}.png`,
                played: homeStats.matches,
                over: homeStats.home.ft[type].over,
                under: homeStats.home.ft[type].under,
                averageGoalscored: homeStats.goalsscored.fthome.average,
                cleanSheet: homeStats.conceded.fthome.cleansheets,
                atLeastOneGoal: homeStats.goalsscored.fthome.atleastonegoal,
                order: 1,
              },
              {
                teamId: awayStats.team._id,
                teamName: awayStats.team.abbr,
                teamLogo: `${this.appConfigService.get('apiBaseUrl').betRadarTeamLogo}/crest/small/${awayStats.team._id}.png`,
                played: awayStats.matches,
                over: awayStats.home.ft[type].over,
                under: awayStats.home.ft[type].under,
                averageGoalscored: awayStats.goalsscored.fthome.average,
                cleanSheet: awayStats.conceded.fthome.cleansheets,
                atLeastOneGoal: awayStats.goalsscored.fthome.atleastonegoal,
                order: 2,
              },
            ],
          });

          homefulltime.push(obj);
        });
        // Home Matches 1st Half
        if (homeStats.home.p1) {
          Object.keys(homeStats.home.p1).forEach(type => {
            const obj = new OverUnderTypeModel({
              id: type.replace('.', ''),
              spread: type,
              teams: [
                {
                  teamId: homeStats.team._id,
                  teamName: homeStats.team.abbr,
                  teamLogo: `${this.appConfigService.get('apiBaseUrl').betRadarTeamLogo}/crest/small/${homeStats.team._id}.png`,
                  played: homeStats.matches,
                  over: homeStats.home.p1[type].over,
                  under: homeStats.home.p1[type].under,
                  averageGoalscored: homeStats.goalsscored.p1home.average,
                  cleanSheet: homeStats.conceded.p1home.cleansheets,
                  atLeastOneGoal: homeStats.goalsscored.p1home.atleastonegoal,
                  order: 1,
                },
                {
                  teamId: awayStats.team._id,
                  teamName: awayStats.team.abbr,
                  teamLogo: `${this.appConfigService.get('apiBaseUrl').betRadarTeamLogo}/crest/small/${awayStats.team._id}.png`,
                  played: awayStats.matches,
                  over: awayStats.home.p1[type].over,
                  under: awayStats.home.p1[type].under,
                  averageGoalscored: awayStats.goalsscored.p1home.average,
                  cleanSheet: awayStats.conceded.p1home.cleansheets,
                  atLeastOneGoal: awayStats.goalsscored.p1home.atleastonegoal,
                  order: 2,
                },
              ],
            });

            home1sthalf.push(obj);
          });
        }
        // Home Matches 2nd Half
        if (homeStats.home.p2) {
          Object.keys(homeStats.home.p2).forEach(type => {
            const obj = new OverUnderTypeModel({
              id: type.replace('.', ''),
              spread: type,
              teams: [
                {
                  teamId: homeStats.team._id,
                  teamName: homeStats.team.abbr,
                  teamLogo: `${this.appConfigService.get('apiBaseUrl').betRadarTeamLogo}/crest/small/${homeStats.team._id}.png`,
                  played: homeStats.matches,
                  over: homeStats.home.p2[type].over,
                  under: homeStats.home.p2[type].under,
                  averageGoalscored: homeStats.goalsscored.p2home.average,
                  cleanSheet: homeStats.conceded.p2home.cleansheets,
                  atLeastOneGoal: homeStats.goalsscored.p2home.atleastonegoal,
                  order: 1,
                },
                {
                  teamId: awayStats.team._id,
                  teamName: awayStats.team.abbr,
                  teamLogo: `${this.appConfigService.get('apiBaseUrl').betRadarTeamLogo}/crest/small/${awayStats.team._id}.png`,
                  played: awayStats.matches,
                  over: awayStats.home.p2[type].over,
                  under: awayStats.home.p2[type].under,
                  averageGoalscored: awayStats.goalsscored.p2home.average,
                  cleanSheet: awayStats.conceded.p2home.cleansheets,
                  atLeastOneGoal: awayStats.goalsscored.p2home.atleastonegoal,
                  order: 2,
                },
              ],
            });

            home2ndhalf.push(obj);
          });
        }
        // Away Matches Full Time
        Object.keys(homeStats.away.ft).forEach(type => {
          const obj = new OverUnderTypeModel({
            id: type.replace('.', ''),
            spread: type,
            teams: [
              {
                teamId: homeStats.team._id,
                teamName: homeStats.team.abbr,
                teamLogo: `${this.appConfigService.get('apiBaseUrl').betRadarTeamLogo}/crest/small/${homeStats.team._id}.png`,
                played: homeStats.matches,
                over: homeStats.away.ft[type].over,
                under: homeStats.away.ft[type].under,
                averageGoalscored: homeStats.goalsscored.ftaway.average,
                cleanSheet: homeStats.conceded.ftaway.cleansheets,
                atLeastOneGoal: homeStats.goalsscored.ftaway.atleastonegoal,
                order: 1,
              },
              {
                teamId: awayStats.team._id,
                teamName: awayStats.team.abbr,
                teamLogo: `${this.appConfigService.get('apiBaseUrl').betRadarTeamLogo}/crest/small/${awayStats.team._id}.png`,
                played: awayStats.matches,
                over: awayStats.away.ft[type].over,
                under: awayStats.away.ft[type].under,
                averageGoalscored: awayStats.goalsscored.ftaway.average,
                cleanSheet: awayStats.conceded.ftaway.cleansheets,
                atLeastOneGoal: awayStats.goalsscored.ftaway.atleastonegoal,
                order: 2,
              },
            ],
          });

          awayfulltime.push(obj);
        });
        // Away Matches 1st Half
        if (homeStats.away.p1) {
          Object.keys(homeStats.away.p1).forEach(type => {
            const obj = new OverUnderTypeModel({
              id: type.replace('.', ''),
              spread: type,
              teams: [
                {
                  teamId: homeStats.team._id,
                  teamName: homeStats.team.abbr,
                  teamLogo: `${this.appConfigService.get('apiBaseUrl').betRadarTeamLogo}/crest/small/${homeStats.team._id}.png`,
                  played: homeStats.matches,
                  over: homeStats.away.p1[type].over,
                  under: homeStats.away.p1[type].under,
                  averageGoalscored: homeStats.goalsscored.p1away.average,
                  cleanSheet: homeStats.conceded.p1away.cleansheets,
                  atLeastOneGoal: homeStats.goalsscored.p1away.atleastonegoal,
                  order: 1,
                },
                {
                  teamId: awayStats.team._id,
                  teamName: awayStats.team.abbr,
                  teamLogo: `${this.appConfigService.get('apiBaseUrl').betRadarTeamLogo}/crest/small/${awayStats.team._id}.png`,
                  played: awayStats.matches,
                  over: awayStats.away.p1[type].over,
                  under: awayStats.away.p1[type].under,
                  averageGoalscored: awayStats.goalsscored.p1away.average,
                  cleanSheet: awayStats.conceded.p1away.cleansheets,
                  atLeastOneGoal: awayStats.goalsscored.p1away.atleastonegoal,
                  order: 2,
                },
              ],
            });

            away1sthalf.push(obj);
          });
        }
        // Away Matches 2nd Half
        if (homeStats.away.p2) {
          Object.keys(homeStats.away.p2).forEach(type => {
            const obj = new OverUnderTypeModel({
              id: type.replace('.', ''),
              spread: type,
              teams: [
                {
                  teamId: homeStats.team._id,
                  teamName: homeStats.team.abbr,
                  teamLogo: `${this.appConfigService.get('apiBaseUrl').betRadarTeamLogo}/crest/small/${homeStats.team._id}.png`,
                  played: homeStats.matches,
                  over: homeStats.away.p2[type].over,
                  under: homeStats.away.p2[type].under,
                  averageGoalscored: homeStats.goalsscored.p2away.average,
                  cleanSheet: homeStats.conceded.p2away.cleansheets,
                  atLeastOneGoal: homeStats.goalsscored.p2away.atleastonegoal,
                  order: 1,
                },
                {
                  teamId: awayStats.team._id,
                  teamName: awayStats.team.abbr,
                  teamLogo: `${this.appConfigService.get('apiBaseUrl').betRadarTeamLogo}/crest/small/${awayStats.team._id}.png`,
                  played: awayStats.matches,
                  over: awayStats.away.p2[type].over,
                  under: awayStats.away.p2[type].under,
                  averageGoalscored: awayStats.goalsscored.p2away.average,
                  cleanSheet: awayStats.conceded.p2away.cleansheets,
                  atLeastOneGoal: awayStats.goalsscored.p2away.atleastonegoal,
                  order: 2,
                },
              ],
            });

            away2ndhalf.push(obj);
          });
        }

        const overUnder = new OverUnderModel({
          allfulltime: allfulltime,
          all1sthalf: all1sthalf,
          all2ndhalf: all2ndhalf,
          homefulltime: homefulltime,
          home1sthalf: home1sthalf,
          home2ndhalf: home2ndhalf,
          awayfulltime: awayfulltime,
          away1sthalf: away1sthalf,
          away2ndhalf: away2ndhalf,
        });

        // Add percentages
        Object.keys(overUnder).forEach(type => {
          overUnder[type].forEach(tab => {
            tab.teams.forEach(team => {
              // Over/Under Percentage
              const tabTeam: OverUnderTeamModel = team;

              team.overPerc = Math.round((tabTeam.over / (tabTeam.over + tabTeam.under)) * 100 * 100) / 100;
              team.underPerc = Math.round((tabTeam.under / (tabTeam.over + tabTeam.under)) * 100 * 100) / 100;

              // Clean Sheets Percentage
              team.cleanSheetsPerc = Math.floor((tabTeam.cleanSheet / (tabTeam.over + tabTeam.under)) * 100);

              // At least one goal Percentage
              team.atLeastOneGoalPerc = Math.floor((tabTeam.atLeastOneGoal / (tabTeam.over + tabTeam.under)) * 100);
            });
          });
        });

        this.statisticsStore.updateOverUnderData(overUnder);
      } catch (e) {
        // error
      }
    }
  }

  // Parse Goalscorer
  parseGoalscorerData(data: any, homeTeamId: number, awayTeamId: number): void {
    if (data && data.doc[0].data && !isEmpty(data.doc[0].data.teams[homeTeamId]) && !isEmpty(data.doc[0].data.teams[awayTeamId])) {
      const result = data.doc[0].data;

      try {
        const homeTeamName = result.teams[homeTeamId].mediumname;
        const awayTeamName = result.teams[awayTeamId].mediumname;

        const homeGoalscorers: PlayerModel[] = [];
        const awayGoalscorers: PlayerModel[] = [];

        let homeCount = 0;
        let awayCount = 0;
        result.players.forEach(player => {
          const objPlayer = new PlayerModel({
            position: player.player.position.abbr,
            name: player.player.name,
            nationalityFlag: player.player.nationality
              ? `${this.appConfigService.get('apiBaseUrl').betRadarTeamLogo}/crest/small/${player.player.nationality.a2}.png`
              : '',
            matches: player.total.matches,
            goals: player.total.goals,
          });

          if (player.teams[homeTeamId] !== undefined) {
            // Home Goalscorers
            homeCount++;
            if (homeCount <= this.appConfigService.get('sports').statistics.maximumRows) {
              homeGoalscorers.push(objPlayer);
            }
          } else if (player.teams[awayTeamId] !== undefined) {
            // Away Goalscorers
            awayCount++;
            if (awayCount <= this.appConfigService.get('sports').statistics.maximumRows) {
              awayGoalscorers.push(objPlayer);
            }
          }
        });

        const goalscorer = new GoalscorerModel({
          homeTeamId: homeTeamId,
          homeTeamName: homeTeamName,
          awayTeamId: awayTeamId,
          awayTeamName: awayTeamName,
          homeGoalscorers: homeGoalscorers,
          awayGoalscorers: awayGoalscorers,
        });

        this.statisticsStore.updateGoalscorerData(goalscorer);
      } catch (e) {
        // error
      }
    }
  }

  parseAssistsData(data: any, homeTeamId: number, awayTeamId: number): void {
    if (data && data.doc[0].data && !isEmpty(data.doc[0].data.teams[homeTeamId]) && !isEmpty(data.doc[0].data.teams[awayTeamId])) {
      const result = data.doc[0].data;

      try {
        const homeTeamName = result.teams[homeTeamId].mediumname;
        const awayTeamName = result.teams[awayTeamId].mediumname;

        const homeAssists: PlayerModel[] = [];
        const awayAssists: PlayerModel[] = [];

        let homeCount = 0;
        let awayCount = 0;
        result.players.forEach(player => {
          const objPlayer = new PlayerModel({
            position: player.player.position.abbr,
            name: player.player.name,
            nationalityFlag: player.player.nationality
              ? `${this.appConfigService.get('apiBaseUrl').betRadarTeamLogo}/crest/small/${player.player.nationality.a2}.png`
              : '',
            matches: player.total.matches,
            assists: player.total.assists,
          });

          if (player.teams[homeTeamId] !== undefined) {
            // Home Assists
            homeCount++;
            if (homeCount <= this.appConfigService.get('sports').statistics.maximumRows) {
              homeAssists.push(objPlayer);
            }
          } else if (player.teams[awayTeamId] !== undefined) {
            // Away Assists
            awayCount++;
            if (awayCount <= this.appConfigService.get('sports').statistics.maximumRows) {
              awayAssists.push(objPlayer);
            }
          }
        });

        const assists = new AssistsModel({
          homeTeamId: homeTeamId,
          homeTeamName: homeTeamName,
          awayTeamId: awayTeamId,
          awayTeamName: awayTeamName,
          homeAssists: homeAssists,
          awayAssists: awayAssists,
        });

        this.statisticsStore.updateAssistsData(assists);
      } catch (e) {
        // error
      }
    }
  }

  parseCardsData(data: any, homeTeamId: number, awayTeamId: number): void {
    if (data && data.doc[0].data && !isEmpty(data.doc[0].data.teams[homeTeamId]) && !isEmpty(data.doc[0].data.teams[awayTeamId])) {
      const result = data.doc[0].data;

      try {
        const homeTeamName = result.teams[homeTeamId].mediumname;
        const awayTeamName = result.teams[awayTeamId].mediumname;

        const homeCards: PlayerModel[] = [];
        const awayCards: PlayerModel[] = [];

        let homeCount = 0;
        let awayCount = 0;
        result.players.forEach(player => {
          const objPlayer = new PlayerModel({
            position: player.player.position.abbr,
            name: player.player.name,
            nationalityFlag: player.player.nationality
              ? `${this.appConfigService.get('apiBaseUrl').betRadarTeamLogo}/crest/small/${player.player.nationality.a2}.png`
              : '',
            matches: player.total.matches,
            yellowCards: player.total.yellow_cards !== undefined ? player.total.yellow_cards : '-',
            yellowRedCards: player.total.yellowred_cards !== undefined ? player.total.yellowred_cards : '-',
            redCards: player.total.red_cards !== undefined ? player.total.red_cards : '-',
          });

          if (player.teams[homeTeamId] !== undefined) {
            // Home Cards
            homeCount++;
            if (homeCount <= this.appConfigService.get('sports').statistics.maximumRows) {
              homeCards.push(objPlayer);
            }
          } else if (player.teams[awayTeamId] !== undefined) {
            // Away Cards
            awayCount++;
            if (awayCount <= this.appConfigService.get('sports').statistics.maximumRows) {
              awayCards.push(objPlayer);
            }
          }
        });

        const cards = new CardsModel({
          homeTeamId: homeTeamId,
          homeTeamName: homeTeamName,
          awayTeamId: awayTeamId,
          awayTeamName: awayTeamName,
          homeCards: homeCards,
          awayCards: awayCards,
        });

        this.statisticsStore.updateCardsData(cards);
      } catch (e) {
        // error
      }
    }
  }

  parseLeagueRankingData(data: any, homeTeamId: number, awayTeamId: number): void {
    if (data && data.doc[0].data && !isEmpty(data.doc[0].data.tables)) {
      const result = data.doc[0].data;

      try {
        const teams: LeagueRankingTeamModel[] = [];

        // Home Team
        let teamHomeName = '';

        let position = 0;
        let played = 0;
        let won = 0;
        let drawn = 0;
        let lost = 0;
        let goalsFor = 0;
        let goalsAgainst = 0;
        let goalsDifference = 0;
        let points = 0;

        let seasonTable;

        if (result.length !== 0 && result.tables.length !== 0) {
          seasonTable = result.tables;
          if (seasonTable.length > 1) {
            seasonTable = result.tables.filter(item => item.tablerows.filter(tablerow => tablerow.team.uid === homeTeamId).length > 0);
          }

          const homeTeamPoints = seasonTable[0].tablerows.filter(item => item.team.uid === homeTeamId)[0];

          if (homeTeamPoints !== undefined) {
            teamHomeName = homeTeamPoints.team.abbr;
            position = homeTeamPoints.pos;
            played = homeTeamPoints.total;
            won = homeTeamPoints.winTotal;
            drawn = homeTeamPoints.drawTotal;
            lost = homeTeamPoints.lossTotal;
            goalsFor = homeTeamPoints.goalsForTotal;
            goalsAgainst = homeTeamPoints.goalsAgainstTotal;
            goalsDifference = homeTeamPoints.goalDiffTotal;
            points = homeTeamPoints.pointsTotal;
          }
        }

        let objTeam = new LeagueRankingTeamModel({
          position: position,
          teamId: homeTeamId,
          teamName: teamHomeName,
          teamLogo: `${this.appConfigService.get('apiBaseUrl').betRadarTeamLogo}/crest/small/${homeTeamId}.png`,
          played: played,
          won: won,
          drawn: drawn,
          lost: lost,
          goalsFor: goalsFor,
          goalsAgainst: goalsAgainst,
          goalsDifference: goalsDifference,
          points: points,
        });

        teams.push(objTeam);

        // Away Team
        let teamAwayName = '';

        position = 0;
        played = 0;
        won = 0;
        drawn = 0;
        lost = 0;
        goalsFor = 0;
        goalsAgainst = 0;
        goalsDifference = 0;
        points = 0;

        if (result.length !== 0 && result.tables.length !== 0) {
          seasonTable = result.tables;
          if (seasonTable.length > 1) {
            seasonTable = result.tables.filter(item => item.tablerows.filter(tablerow => tablerow.team.uid === awayTeamId).length > 0);
          }

          const awayTeamPoints = seasonTable[0].tablerows.filter(item => item.team.uid === awayTeamId)[0];

          if (awayTeamPoints !== undefined) {
            teamAwayName = awayTeamPoints.team.abbr;
            position = awayTeamPoints.pos;
            played = awayTeamPoints.total;
            won = awayTeamPoints.winTotal;
            drawn = awayTeamPoints.drawTotal;
            lost = awayTeamPoints.lossTotal;
            goalsFor = awayTeamPoints.goalsForTotal;
            goalsAgainst = awayTeamPoints.goalsAgainstTotal;
            goalsDifference = awayTeamPoints.goalDiffTotal;
            points = awayTeamPoints.pointsTotal;
          }
        }

        objTeam = new LeagueRankingTeamModel({
          position: position,
          teamId: awayTeamId,
          teamName: teamAwayName,
          teamLogo: `${this.appConfigService.get('apiBaseUrl').betRadarTeamLogo}/crest/small/${awayTeamId}.png`,
          played: played,
          won: won,
          drawn: drawn,
          lost: lost,
          goalsFor: goalsFor,
          goalsAgainst: goalsAgainst,
          goalsDifference: goalsDifference,
          points: points,
        });

        teams.push(objTeam);

        const leagueRanking = new LeagueRankingModel({
          teams: teams,
        });

        this.statisticsStore.updateLeagueRankingData(leagueRanking);
      } catch (e) {
        // error
      }
    }
  }
  // eslint-disable-next-line max-lines
}
