import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { VirtualsCampaignBanner } from 'src/app/shared/models/virtuals.model';

@Component({
  selector: 'virtuals-campaign-banner',
  templateUrl: './virtuals-campaign-banner.component.html',
  styleUrls: ['./virtuals-campaign-banner.component.scss'],
  // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None, // required in order to be able to style <a> elements in content
})
export class VirtualsCampaignBannerComponent {
  @Input() campaign: VirtualsCampaignBanner;

  constructor(private readonly router: Router) {}

  openCampaign() {
    if (!this.campaign.bannerURL) return;

    if (this.campaign.openInNewTab) {
      window.open(this.campaign.bannerURL, '_blank');
    } else {
      this.router.navigateByUrl(this.campaign.bannerURL);
    }
  }
}
