import { Injectable } from '@angular/core';
import { filter, tap } from 'rxjs/operators';
import { ActivationEnd, Router } from '@angular/router';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { ApplicationStore } from 'src/app/core/state/application/application.store';
import { ProductType, VirtualsLeagueType } from 'src/app/shared/models/product.model';
import { has } from 'lodash-es';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { VirtualsMyBetsService } from 'src/app/modules/my-bets/services/my-bets-virtuals.service';
import { SportsMyBetsService } from 'src/app/modules/my-bets/services/my-bets-sports.service';
import { JackpotBetsMyBetsService } from 'src/app/modules/my-bets/services/my-bets-jackpotbets.service';

@Injectable({
  providedIn: 'root',
})
export class ProductTypeService {
  constructor(
    private readonly applicationQuery: ApplicationQuery,
    private readonly applicationStore: ApplicationStore,
    private readonly router: Router,
    private readonly accountQuery: AccountQuery,
    private readonly virtualsMyBetsService: VirtualsMyBetsService,
    private readonly sportsMyBetsService: SportsMyBetsService,
    private readonly jackpotBetsMyBetsService: JackpotBetsMyBetsService
  ) {}

  initialize(): void {
    this.router.events.subscribe(event => {
      if (event instanceof ActivationEnd) {
        if (event.snapshot.data) {
          if (event.snapshot.data.productType) {
            this.updateActiveProduct(event.snapshot.data.productType);

            // Get open my-bets count
            this.accountQuery.isAuthenticated$
              .pipe(
                filter(isAuth => isAuth && !this.applicationQuery.inMyBets),
                tap(() => {
                  if (this.applicationQuery.isVirtuals) {
                    this.virtualsMyBetsService.getMyBetsCount();
                  } else if (this.applicationQuery.isJackpotBets) {
                    this.jackpotBetsMyBetsService.getMyBetsCount();
                  } else if (this.applicationQuery.isSportsBook) {
                    this.sportsMyBetsService.getMyBetsCount();
                  }
                })
              )
              .subscribe();
          }

          // the virtualsLeagueType value needs to be saved even when it is
          // undefined (but not when the property doesn't exist in the data)
          if (has(event.snapshot.data, 'virtualsLeagueType')) {
            this.updateActiveVirtualsLeague(event.snapshot.data.virtualsLeagueType);
          }

          if (event.snapshot.data.inCoupon !== undefined) {
            this.updateInCoupon(event.snapshot.data.inCoupon);
          }

          if (event.snapshot.data.inMyBets !== undefined) {
            this.updateInMyBets(event.snapshot.data.inMyBets);
          }

          if (event.snapshot.data.isSportsSection !== undefined) {
            this.updateInSportsSection(event.snapshot.data.isSportsSection);
          }
        }
      }
    });
  }

  updateActiveProduct(productType: ProductType): void {
    if (this.applicationQuery.activeProduct !== productType) {
      this.applicationStore.update({ activeProduct: productType });
    }
  }

  updateActiveVirtualsLeague(virtualsLeagueType: VirtualsLeagueType): void {
    if (this.applicationQuery.activeVirtualsLeague !== virtualsLeagueType) {
      this.applicationStore.update({ activeVirtualsLeague: virtualsLeagueType });
    }
  }

  updateInCoupon(inCoupon: boolean): void {
    if (this.applicationQuery.inCoupon !== inCoupon) {
      this.applicationStore.updateUI({ inCoupon });
    }
  }

  updateInMyBets(inMyBets: boolean): void {
    if (this.applicationQuery.inMyBets !== inMyBets) {
      this.applicationStore.updateUI({ inMyBets });
    }
  }

  updateInSportsSection(isSportsSection: boolean): void {
    if (this.applicationQuery.isSportsSection !== isSportsSection) {
      this.applicationStore.updateUI({ isSportsSection });
    }
  }
}
