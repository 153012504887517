import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { StatisticsService } from 'src/app/core/services/statistics.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { StatisticsQuery } from 'src/app/core/state/statistics/statistics.query';
import { MatchService } from 'src/app/modules/sport/services/match.service';
import { MatchQuery } from 'src/app/modules/sport/state/match-view/match.query';
import { fadeIn } from 'src/app/shared/animations';
import { MatchModel, ProviderType } from 'src/app/shared/models/sport.model';
import { HeaderTab, MatchesTab, SegmentTab, SpreadTab, StatisticsType, StatsTab, TeamTab } from 'src/app/shared/models/statistics.model';
import { IdType } from 'src/app/shared/utils/id-convertor';
import { getCookie } from 'src/app/shared/utils/cookie-actions';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeIn()],
})
export class StatisticsComponent implements OnInit, OnDestroy {
  @Input() match: MatchModel;
  @Input() tournamentName = '';
  @Input() statisticsType: StatisticsType;
  @Input() eventLocation: string = undefined; // Used for data layer events
  @Input() isLive: boolean = false;

  enableStatistics$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  headerTab: typeof HeaderTab = HeaderTab;
  teamTab: typeof TeamTab = TeamTab;
  matchesTab: typeof MatchesTab = MatchesTab;
  segmentTab: typeof SegmentTab = SegmentTab;
  spreadTab: typeof SpreadTab = SpreadTab;
  statsTab: typeof StatsTab = StatsTab;
  providerType: typeof ProviderType = ProviderType;
  headerTabSelected: HeaderTab = HeaderTab.NotSelected;
  lastMatchesTeamTabSelected: TeamTab = TeamTab.Home;
  nextMatchesTeamTabSelected: TeamTab = TeamTab.Home;
  playerStatsTeamTabSelected: TeamTab = TeamTab.Home;
  playerStatsTabSelected: StatsTab = StatsTab.Goals;
  overUnderMatchesSelected: MatchesTab = MatchesTab.All;
  overUnderSegmentSelected: SegmentTab = SegmentTab.FullTime;
  overUnderSpreadSelected: SpreadTab;
  destroy$: Subject<boolean> = new Subject<boolean>();
  readonly isFootballGo$ = new BehaviorSubject(false);
  readonly cppWidget$ = new BehaviorSubject<boolean>(false);
  constructor(
    public statisticsQuery: StatisticsQuery,
    readonly matchQuery: MatchQuery,
    private readonly statisticsService: StatisticsService,
    private readonly appConfigService: AppConfigService,
    private readonly matchService: MatchService,
    private readonly accountQuery: AccountQuery,
    private readonly dataLayerService: DataLayerService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    // show loader
    this.matchService
      .getFixtureInfo(this.match?.id, this.isLive ? IdType.Live : IdType.Prematch)
      .pipe(first())
      .subscribe({
        next: () => {
          this.setWidget();
        },
        error: () => {
          this.enableStatistics$.next(true);
          this.cppWidget$.next(true);
        },
      });
  }

  getBetRadarMatchStatistics(matchId: number): void {
    this.statisticsService
      .getMatchStatistics(matchId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(success => {
        if (!success) {
          return;
        }

        this.statisticsQuery.matchStatisticsData$.pipe(takeUntil(this.destroy$)).subscribe(data => {
          if (data) {
            this.getBetRadarStatistics(data.homeTeamId, data.awayTeamId, data.seasonId);
          }
        });
      });
  }

  getVSLMatchStatistics(homeTeamId: number, awayTeamId: number) {
    if (homeTeamId && awayTeamId) {
      this.getVSLStatistics(homeTeamId, awayTeamId);
    }
  }

  getVSLStatistics(homeTeamId: number, awayTeamId: number) {
    this.statisticsService.getVSLStatistics(homeTeamId, awayTeamId);
    this.statisticsQuery.winProbabilityData$.pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data) {
        this.enableStatistics$.next(true);

        setTimeout(() => {
          if (!this.statisticsQuery.expandedStatistics) {
            this.statisticsService.updateExpandedStatistics(true);
          }
        });

        if (this.headerTabSelected === HeaderTab.NotSelected) {
          this.headerTabSelected = HeaderTab.WinProbability;
        }
      }
    });
    this.statisticsQuery.headToHeadData$.pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data) {
        this.enableStatistics$.next(true);

        setTimeout(() => {
          if (!this.statisticsQuery.expandedStatistics) {
            this.statisticsService.updateExpandedStatistics(true);
          }
        });

        if (this.headerTabSelected === HeaderTab.NotSelected) {
          this.headerTabSelected = this.isFootballGo$?.value ? HeaderTab.WinProbability : HeaderTab.HeadToHead;
        }
      }
    });

    this.statisticsQuery.lastMatchesData$.pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data) {
        this.enableStatistics$.next(true);

        setTimeout(() => {
          if (!this.statisticsQuery.expandedStatistics) {
            this.statisticsService.updateExpandedStatistics(true);
          }
        });

        if (this.headerTabSelected === HeaderTab.NotSelected) {
          this.headerTabSelected = HeaderTab.LastMatches;
        }
      }
    });
  }

  getBetRadarStatistics(homeTeamId: number, awayTeamId: number, seasonId: number): void {
    this.statisticsService
      .getStatistics(homeTeamId, awayTeamId, seasonId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.statisticsQuery.headToHeadData$.pipe(takeUntil(this.destroy$)).subscribe(data => {
          if (data) {
            this.enableStatistics$.next(true);

            setTimeout(() => {
              if (!this.statisticsQuery.expandedStatistics) {
                this.statisticsService.updateExpandedStatistics(true);
              }
            });

            if (this.headerTabSelected === HeaderTab.NotSelected) {
              this.headerTabSelected = this.isFootballGo$?.value ? HeaderTab.WinProbability : HeaderTab.HeadToHead;
            }
          }
        });

        this.statisticsQuery.lastMatchesData$.pipe(takeUntil(this.destroy$)).subscribe(data => {
          if (data) {
            this.enableStatistics$.next(true);

            setTimeout(() => {
              if (!this.statisticsQuery.expandedStatistics) {
                this.statisticsService.updateExpandedStatistics(true);
              }
            });

            if (this.headerTabSelected === HeaderTab.NotSelected) {
              this.headerTabSelected = HeaderTab.LastMatches;
            }
          }
        });

        this.statisticsQuery.nextMatchesData$.pipe(takeUntil(this.destroy$)).subscribe(data => {
          if (data) {
            this.enableStatistics$.next(true);

            setTimeout(() => {
              if (!this.statisticsQuery.expandedStatistics) {
                this.statisticsService.updateExpandedStatistics(true);
              }
            });

            if (this.headerTabSelected === HeaderTab.NotSelected) {
              this.headerTabSelected = HeaderTab.NextMatches;
            }
          }
        });

        this.statisticsQuery.overUnderData$.pipe(takeUntil(this.destroy$)).subscribe(data => {
          if (data) {
            this.enableStatistics$.next(true);

            setTimeout(() => {
              if (!this.statisticsQuery.expandedStatistics) {
                this.statisticsService.updateExpandedStatistics(true);
              }
            });

            if (this.headerTabSelected === HeaderTab.NotSelected) {
              this.headerTabSelected = HeaderTab.OverUnder;
            }

            Object.keys(data).forEach(key => {
              this.overUnderSpreadSelected = data[key][0] ? data[key][0].id : undefined;
            });
          }
        });

        this.statisticsQuery.goalscorerData$.pipe(takeUntil(this.destroy$)).subscribe(data => {
          if (data) {
            this.enableStatistics$.next(true);

            setTimeout(() => {
              if (!this.statisticsQuery.expandedStatistics) {
                this.statisticsService.updateExpandedStatistics(true);
              }
            });

            if (this.headerTabSelected === HeaderTab.NotSelected) {
              this.headerTabSelected = HeaderTab.PlayerStats;
            }
          }
        });

        this.statisticsQuery.assistsData$.pipe(takeUntil(this.destroy$)).subscribe(data => {
          if (data) {
            this.enableStatistics$.next(true);

            setTimeout(() => {
              if (!this.statisticsQuery.expandedStatistics) {
                this.statisticsService.updateExpandedStatistics(true);
              }
            });

            if (this.headerTabSelected === HeaderTab.NotSelected) {
              this.headerTabSelected = HeaderTab.PlayerStats;
            }
          }
        });

        this.statisticsQuery.cardsData$.pipe(takeUntil(this.destroy$)).subscribe(data => {
          if (data) {
            this.enableStatistics$.next(true);

            setTimeout(() => {
              if (!this.statisticsQuery.expandedStatistics) {
                this.statisticsService.updateExpandedStatistics(true);
              }
            });

            if (this.headerTabSelected === HeaderTab.NotSelected) {
              this.headerTabSelected = HeaderTab.PlayerStats;
            }
          }
        });

        this.statisticsQuery.leagueRankingData$.pipe(takeUntil(this.destroy$)).subscribe(data => {
          if (data) {
            this.enableStatistics$.next(true);

            setTimeout(() => {
              if (!this.statisticsQuery.expandedStatistics) {
                this.statisticsService.updateExpandedStatistics(true);
              }
            });

            if (this.headerTabSelected === HeaderTab.NotSelected) {
              this.headerTabSelected = HeaderTab.LeagueRanking;
            }
          }
        });
      });
  }

  statisticsBetradarPopup(language: string = 'en'): void {
    let url = this.appConfigService.get('apiBaseUrl').betRadarStatistics;

    if (url.endsWith('/')) {
      url = url.slice(0, -1);
    }

    const fixtureInfo = this.matchQuery.fixtureInfo[0];
    const externalId = fixtureInfo?.sources?.find(t => t.sourceId === this.providerType.InnBets.toString())?.providerId?.replace(/\D/g, '');
    window.open(`${url}/${language}/match/${externalId}`, '_blank');
  }

  closeStatistics(): void {
    this.statisticsService.updateSelectedEventId(this.match.id);
  }

  selectHeaderTab(tab: HeaderTab): void {
    this.headerTabSelected = tab;
  }

  indexTrackBy(index: number): number {
    return index;
  }

  private setWidget(): void {
    const isCppSport = this.appConfigService.get('sports').cppWidgetSportIds.some(sportId => sportId === this.match.sportId);
    if (isCppSport && this.statisticsQuery.enableCPPPreMatchWidget) {
      this.createDataLayerEvent('bet_stimulus');
      this.enableStatistics$.next(true);
      this.cppWidget$.next(true);
      setTimeout(() => {
        if (!this.statisticsQuery.expandedStatistics) {
          this.statisticsService.updateExpandedStatistics(true);
        }
      });
    } else {
      this.cppWidget$.next(false);
      this.isFootballGo$?.next(this.match && this.match.sportId === this.appConfigService.get('sports').footballGoId);
      const fixtureInfo = this.matchQuery.fixtureInfo[0];
      let source: string = '';
      if (this.isFootballGo$?.value) {
        source = 'vsl';
        this.getVSLMatchStatistics(
          parseInt(
            fixtureInfo?.participants?.find(t => t.homeAwayId === '1')?.sources?.find(t => t.sourceId === this.providerType.FootballGo)
              ?.providerId,
            10
          ),
          parseInt(
            fixtureInfo?.participants?.find(t => t.homeAwayId === '2')?.sources?.find(t => t.sourceId === this.providerType.FootballGo)
              ?.providerId,
            10
          )
        );
      } else {
        source = 'bet_radar';
        const externalId = fixtureInfo?.sources
          ?.find(t => t.sourceId === this.providerType.InnBets.toString())
          ?.providerId?.replace(/\D/g, '');
        this.getBetRadarMatchStatistics(parseInt(externalId, 10));
      }

      this.createDataLayerEvent(source);
    }
  }

  private createDataLayerEvent(source: string): void {
    const { eventLocation } = this;
    this.dataLayerService.createDataLayerEvent({
      ABTestHomePage: getCookie('ABTestHomePage'),
      event: 'SB-stats-click',
      user_id: this.accountQuery.userData?.id,
      stats_source: source,
      location: eventLocation ? eventLocation : window.location.pathname,
    });
  }
}
