import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { MessageService } from 'src/app/core/services/message.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { MessagesQuery } from 'src/app/core/state/messages/messages.query';
import { ApplicationService } from 'src/app/core/services/application.service';
import { BehaviorSubject } from 'rxjs';
import { SportsbookFreeBetService } from 'src/app/modules/freebets/services/sportsbook-free-bet.service';
import { ActiveReward } from 'src/app/modules/freebets/models/freebets.model';
import { DataLayerService } from 'src/app/core/services/data-layer.service';

@Component({
  selector: 'header-bk-user',
  templateUrl: './bk-user-header.component.html',
  styleUrls: ['./bk-user-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BKUserHeaderComponent implements OnInit {
  readonly messagesCount$ = this.messagesQuery.unreadMessagesCount$;
  readonly showMessageCountBadge$ = this.messagesQuery.unreadMessagesCount$.pipe(map(count => count > 0));

  private readonly ENABLE_WTH_BANK_PROFILE = this.appConfigService.get('account').enableWithdrawalBankProfile;
  private readonly freebetSlowRollout = this.appConfigService.get('account').slowRollout?.freebetTooltipInHeader;
  isTooltipVisible$ = this.freebetsService.getTooltipVisibility$();
  isPopoverVisible$ = this.freebetsService.getPopoverVisibility$();

  freebetTooltipEnabled: boolean = false;
  freeBets: ActiveReward[] = [];
  newFreeBetsSum$ = new BehaviorSubject<number>(0);
  newFreebetLength$ = new BehaviorSubject<number>(0);

  constructor(
    private readonly accountQuery: AccountQuery,
    private readonly appConfigService: AppConfigService,
    private readonly messagesQuery: MessagesQuery,
    private readonly messagesService: MessageService,
    private readonly applicationService: ApplicationService,
    private readonly freebetsService: SportsbookFreeBetService,
    private readonly dataLayerService: DataLayerService
  ) {}

  ngOnInit(): void {
    this.freebetTooltipEnabled = this.accountQuery.userData.id % 100 < this.freebetSlowRollout;
    // Fetch unread messages count on first load
    if (this.applicationService.getIsFirstLoad()) {
      this.messagesService.getUnreadMessagesCount().subscribe();
      this.applicationService.setIsFirstLoad(false);
    }

    if (this.freebetTooltipEnabled) {
      this.getAvailableFreebets();
    }
  }

  menuItemProfileStatus(): boolean {
    if ((this.accountQuery.userProfileState === 'profileEmpty' || this.accountQuery.sbadIndicatorState) && this.ENABLE_WTH_BANK_PROFILE) {
      return true;
    } else {
      return false;
    }
  }

  getAvailableFreebets(): void {
    this.freebetsService.getAvailableFreebets().subscribe(
      response => {
        this.freeBets = response.data;
        this.handleFreeBets();
      },
      error => {
        console.error('Error fetching freebets:', error);
      }
    );
  }

  handleFreeBets(): void {
    const newFreeBets = this.freeBets.filter(freeBet => freeBet.rewardId > this.freebetsService.lastSeenRewardId);
    const newSum = newFreeBets.reduce((sum, freeBet) => sum + freeBet.value, 0);

    this.newFreebetLength$.next(this.freeBets.length);
    this.newFreeBetsSum$.next(newSum);

    if (newSum > 0) {
      this.freebetsService.enableFreebetDisplay();
      this.dataLayerService.createDataLayerEvent({
        event: 'freebet-popover-visible',
        userId: this.accountQuery.userData?.id,
      });
    }
  }

  hidePopoverFreebet(): void {
    this.freebetsService.hidePopover();
    this.markFreeBetsAsSeen();
  }

  hideFreebetTooltip(): void {
    this.freebetsService.hideTooltip();
    this.markFreeBetsAsSeen();
  }

  markFreeBetsAsSeen(): void {
    const latestRewardId = Math.max(...this.freeBets.map(freeBet => freeBet.rewardId), this.freebetsService.lastSeenRewardId);
    this.freebetsService.markFreeBetsAsSeen(latestRewardId);
  }

  navigateToAccount(): void {
    this.hidePopoverFreebet();
    this.hideFreebetTooltip();
    if (this.newFreeBetsSum$.getValue() > 0 && this.freebetTooltipEnabled) {
      window.location.href = '/account?source=freebetTooltip';
    } else {
      window.location.href = '/account';
    }
  }
}
