<div class="user-header-container">
  <header-bk-user-balance></header-bk-user-balance>
  <a href="/message-center">
    <button class="message-centre">
      <span
        *ngIf="showMessageCountBadge$ | async"
        [appBadge]="messagesCount$ | async"
        [badgeSize]="16"
        [rightAdjustment]="0"
        [topAdjustment]="0"
        badgeClass="badge-red"
      ></span>
      <span class="material-icons message-centre-icon">{{ 'mail' }}</span>
    </button>
  </a>
  <div class="user-account">
      <div class="freebet-tooltip-container">
        <div *ngIf="(isPopoverVisible$ | async) && (newFreebetLength$ | async) > 0" class="popover-container">
          <div class="popover-content">
            <div class="gift-icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.80432 11.0781C2.80432 12.1223 3.40639 12.6021 4.45061 12.6021H11.1016V7.29633H9.69049C8.25117 7.29633 7.42332 6.42145 7.42332 5.39605C7.42332 4.39887 8.11947 3.78739 9.13546 3.78739C10.2173 3.78739 11.1016 4.65287 11.1016 5.9793V7.29633H12.8984V12.6021H19.5494C20.603 12.6021 21.1957 12.1223 21.1957 11.0781V8.98965C21.1957 7.82314 20.5466 7.29633 19.3706 7.29633H17.5268C18.0724 6.76952 18.4017 6.05456 18.4017 5.21731C18.4017 3.33584 16.9059 2 15.0339 2C13.6416 2 12.4657 2.78081 12.0047 4.1731C11.5437 2.78081 10.3584 2 8.96613 2C7.09407 2 5.5983 3.33584 5.5983 5.21731C5.5983 6.05456 5.92756 6.76952 6.47318 7.29633H4.62934C3.50046 7.29633 2.80432 7.82314 2.80432 8.98965V11.0781ZM14.8645 3.78739C15.8805 3.78739 16.5767 4.39887 16.5767 5.39605C16.5767 6.42145 15.7488 7.29633 14.3095 7.29633H12.8984V5.9793C12.8984 4.65287 13.7827 3.78739 14.8645 3.78739ZM4.10253 19.5259C4.10253 21.1251 5.05268 22 6.66133 22H11.1016V13.7498H4.10253V19.5259ZM12.8984 22H17.3387C18.9473 22 19.8975 21.1251 19.8975 19.5259V13.7498H12.8984V22Z" fill="#000C2D" fill-opacity="0.87"/>
              </svg>
            </div>
            <p class="popover-text" i18n>You've got <b>₦{{ newFreeBetsSum$ | async }}</b> Free bets in your Account!</p>
            <button class="close-button" (click)="hidePopoverFreebet()">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.8333 5.34166L14.6583 4.16666L10 8.825L5.34167 4.16666L4.16667 5.34166L8.825 10L4.16667 14.6583L5.34167 15.8333L10 11.175L14.6583 15.8333L15.8333 14.6583L11.175 10L15.8333 5.34166Z" fill="#000C2D" fill-opacity="0.87"/>
              </svg>
            </button>
          </div>
          <div class="arrow"></div>
        </div>
        <div (click)="navigateToAccount()" *ngIf="(isTooltipVisible$ | async) && (newFreebetLength$ | async) > 0" class="outer-circle">
          <div class="circle first"></div>
          <div class="circle second" *ngIf="(isPopoverVisible$ | async)"></div>
          <div class="circle third" *ngIf="(isPopoverVisible$ | async)"></div>
        </div>

      </div>
      <button (click)="navigateToAccount()"  class="account">
        <span *ngIf="menuItemProfileStatus()" class="indicator"></span>
        <span class="material-icons account-icon">{{ 'person' }}</span>
      </button>
  </div>
</div>
