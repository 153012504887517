import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BestSellerOddModel } from 'src/app/shared/models/coupon.model';
@Component({
  selector: 'coupon-empty-betbuilder-odd',
  templateUrl: './coupon-empty-betbuilder-odd.component.html',
  styleUrls: ['./coupon-empty-betbuilder-odd.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CouponEmptyBetbuilderOddComponent {
  private _odd: BestSellerOddModel;

  get odd(): BestSellerOddModel & { Selections: string[] } {
    return {
      ...this._odd,
      Selections: this.parseSelectionName(this._odd.selectionName),
    };
  }

  @Input() set odd(value: BestSellerOddModel) {
    this._odd = value;
  }

  private readonly parseSelectionName = (selectionName: string) =>
    selectionName
      .split(/[\&,]/)
      .map(s => s.trim())
      .filter(s => s.length > 0);
}
