import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { HeaderStore } from 'src/app/modules/header/state/header.store';
import { HeaderState } from 'src/app/modules/header/models/header.model';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { mapActiveUrlByPathKey, type ActiveUrlDomainKeys } from 'src/app/shared/utils/map-active-url';
@Injectable({
  providedIn: 'root',
})
export class HeaderQuery extends Query<HeaderState> {
  guestHeaderContent$ = this.select(state => ({
    loginLabel: state.headerContent?.loginLabel,
    registerLabel: state.headerContent?.registerLabel,
  }));
  productSwitcherContent$ = this.select(state => {
    const isAppAndroid = /btkAppIos/i.test(navigator.userAgent);
    const isAppiOS = /btkAppAndroid/i.test(navigator.userAgent);

    return isAppAndroid || isAppiOS
      ? state.headerContent?.productSwitcherList?.filter(item => !item.link.title.toLowerCase().includes('app'))
      : state.headerContent?.productSwitcherList;
  });

  productSwitcherCurrentSelectedIndex$ = this.applicationQuery.activeUrl$.pipe(
    map(url => {
      const urlPath = url[0].split('?')[0];
      const matcherUrl = mapActiveUrlByPathKey(urlPath as ActiveUrlDomainKeys); // refactor the workaround for issue SB-10405
      const selectedItem = this.getValue().headerContent.productSwitcherList.findIndex(
        item => item.link.url.split('?')[0] === `/${matcherUrl}`
      ); // compare list url path to the matcher
      return selectedItem !== -1
        ? selectedItem
        : this.getValue().headerContent.productSwitcherList.findIndex(item => item.link.url === '/');
    })
  );
  showProductSwitcherBlacklistCheck$ = this.applicationQuery.activeUrl$.pipe(
    distinctUntilChanged(),
    map(url => `/${url.join('/')}`),
    map(url => (url === '' ? true : !this.getValue().productSwitcherBlacklist?.some(listUrl => url.includes(listUrl))))
  );
  showAppBarBlacklistCheck$ = this.applicationQuery.activeUrl$.pipe(
    distinctUntilChanged(),
    map(url => `/${url.join('/')}`),
    map(url => (url === '' ? true : !this.getValue().appBarBlacklist?.some(listUrl => url.includes(listUrl))))
  );
  isLoading$ = this.select(state => state.headerContentLoading);

  constructor(protected store: HeaderStore, private readonly applicationQuery: ApplicationQuery) {
    super(store);
  }
}
