import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MyBetsComponent } from 'src/app/modules/my-bets/my-bets.component';
import { ProductType } from 'src/app/shared/models/product.model';

const routes: Routes = [
  {
    path: '',
    component: MyBetsComponent,
    pathMatch: 'full',
    data: { inMyBets: true, inCoupon: false, product: ProductType.JackpotBets },
  },
  {
    path: ':betsTab',
    component: MyBetsComponent,
    pathMatch: 'full',
    data: { inMyBets: true, inCoupon: false, product: ProductType.JackpotBets },
  },
  { path: '**', redirectTo: 'sports' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MyBetsRoutingModule {}
