import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, filter, finalize, map, mergeAll, takeUntil, tap } from 'rxjs/operators';
import { AccountService } from 'src/app/core/services/account/account.service';
import { CashoutService } from 'src/app/core/services/cashout.service';
import { CurrencyService } from 'src/app/core/services/currency.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { ShopOwnerService } from 'src/app/core/services/shop-owner.service';
import { VariablesService } from 'src/app/core/services/variables.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { CashoutQuery } from 'src/app/core/state/cashout/cashout.query';
import { CashoutStore } from 'src/app/core/state/cashout/cashout.store';
import { expandCollapseSimpler } from 'src/app/shared/animations';
import { UserType } from 'src/app/shared/models/account.model';
import { CashoutModel, CashoutResponseCodes, CashoutStatusTab } from 'src/app/shared/models/cashout.model';
import { BetFinalState } from 'src/app/shared/models/coupon-details.model';
import { CurrencyFormatPipe } from 'src/app/shared/pipes/currency-format.pipe';
import { CashoutActions, FirebaseEvent } from 'src/app/shared/models/datalayer.model';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { ButtonType } from 'src/app/shared/models/button.model';

type CashoutConfirmResponseModalState = 'noresponse' | 'success' | 'fail' | 'refresh' | 'pending' | 'unavailable';

@Component({
  selector: 'app-cashout',
  templateUrl: './cashout.component.html',
  styleUrls: ['./cashout.component.scss'],
  animations: [expandCollapseSimpler()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CashoutComponent implements OnInit, OnDestroy {
  @Input() code: string;
  @Input() parentComponent: string;

  cashoutStatusTab: typeof CashoutStatusTab = CashoutStatusTab;
  cashoutButtonStyle: any = {
    fontSize: '12px',
    fontWeight: 'normal',
    height: '31px',
    margin: '2px',
    padding: '0 8px 0 5px',
    textTransform: 'capitalize',
    letterSpacing: '0.8px',
  };

  betFinalState = BetFinalState;
  cashoutLoggedIn$: Observable<boolean>;
  cashoutSameUser$: Observable<boolean>;
  cashoutShopOwnerSubUser$: Observable<boolean>;
  cashoutWhitelist$: Observable<boolean>;
  item$: Observable<CashoutModel>;

  readonly ButtonType = ButtonType;
  readonly currencyFormatPipe: CurrencyFormatPipe;
  readonly enableCashout$ = new BehaviorSubject(false);
  readonly showMoreInfo$ = new BehaviorSubject(false);
  readonly activeCashout$ = this.cashoutQuery.selectActive();
  readonly isCashoutConfirmModalOpen$ = new BehaviorSubject(false);
  readonly isPerformingCashout$ = new BehaviorSubject(false);
  readonly cashoutSuccessful$ = new BehaviorSubject(false);
  readonly cashoutConfirmResponseModalState$ = new BehaviorSubject<CashoutConfirmResponseModalState>('noresponse');
  readonly cashoutConfirmResponseModalDetails$ = new BehaviorSubject('');

  private readonly destroy$ = new Subject<boolean>();

  constructor(
    readonly variablesService: VariablesService,
    readonly cashoutStore: CashoutStore,
    readonly cashoutQuery: CashoutQuery,
    readonly accountQuery: AccountQuery,
    private readonly cashoutService: CashoutService,
    private readonly accountService: AccountService,
    private readonly applicationQuery: ApplicationQuery,
    private readonly languageService: LanguageService,
    private readonly currencyService: CurrencyService,
    private readonly shopOwnerService: ShopOwnerService,
    private readonly dataLayerService: DataLayerService
  ) {
    this.currencyFormatPipe = new CurrencyFormatPipe(this.applicationQuery, this.languageService, this.currencyService);
  }

  ngOnInit(): void {
    this.item$ = this.cashoutQuery.getCashout(this.code);
    this.cashoutLoggedIn$ = this.accountQuery.isAuthenticated$;
    this.cashoutSameUser$ = this.userIdCheck$();
    this.cashoutWhitelist$ = this.cashoutService.isWhitelistedUserType$();
    this.cashoutShopOwnerSubUser$ =
      this.accountQuery.userData && this.accountQuery.userData.userTypeCode === UserType.ShopOwner
        ? this.shopOwnerCheck$()
        : this.cashoutSameUser$; // If not a shop owner, just mimic the same user checkcombineLatest([
    combineLatest([this.cashoutLoggedIn$, this.cashoutSameUser$, this.cashoutWhitelist$, this.cashoutShopOwnerSubUser$])
      .pipe(
        map(result => result[0] && result[2] && (result[1] || result[3])),
        takeUntil(this.destroy$)
      )
      .subscribe(enableCashout => this.enableCashout$.next(enableCashout));

    this.cashoutQuery
      .selectActive(entity => entity.couponCode === this.code && (!entity.betCashout.allowCashout || entity.betCashout.value === 0))
      .pipe(
        filter(isAvailable => isAvailable),
        tap(() => this.showCashoutConfirmResponseModal('fail', $localize`Cashout is unavailable`)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  toggleMoreInfo(): void {
    this.showMoreInfo$.next(!this.showMoreInfo$.value);
  }

  handleRefreshCashout(item: CashoutModel): void {
    const cashoutRequested = this.pendingListCheck(item);
    if (cashoutRequested) {
      return;
    }

    this.cashoutStore.cashoutRefreshing(true);
    this.cashoutService
      .refreshCashout(item.id, item.couponCode)
      .pipe(finalize(() => this.cashoutStore.cashoutRefreshing(false)))
      .subscribe();
  }

  handleCashout(item: CashoutModel): void {
    // Firebase App Event
    const fbEventId = FirebaseEvent.CashoutClick;
    const additionalParams = {
      userID: this.accountQuery.userData.id || ''
    };
    this.dataLayerService.logFirebaseEvent(fbEventId, additionalParams);
    if (item.betCashout.allowCashout) {
      const cashoutRequested = this.pendingListCheck(item);
      if (cashoutRequested) {
        return;
      }

      this.sendCashoutInitiatedEvent(item);
      if (this.enableCashout$.value || this.cashoutQuery.isEnabled || item.betCashout !== undefined || item.betCashout.availability) {
        this.cashoutStore.cashoutInProgress(true);
        this.cashoutService
          .refreshCashout(item.id, item.couponCode)
          .pipe(
            catchError(() => {
              this.cashoutStore.cashoutInProgress(false);
              return of(false);
            })
          )
          .subscribe(() => {
            this.isCashoutConfirmModalOpen$.next(true);
          });
      }
    }
  }

  handleCloseCashoutConfirm(): void {
    this.isCashoutConfirmModalOpen$.next(false);
    this.cashoutStore.cashoutInProgress(false);
  }

  handleCashoutConfirm(item: CashoutModel): void {
    // Firebase App Event
    const fbEventId = FirebaseEvent.CashoutConfirmClick;
    const additionalParams = {
      userID: this.accountQuery.userData.id || '',
    };
    this.dataLayerService.logFirebaseEvent(fbEventId, additionalParams);
    this.sendCashoutSubmittedEvent(item);
    this.isPerformingCashout$.next(true);
    this.cashoutService
      .cashout(
        item.id,
        item.betCashout.serverData,
        this.accountQuery.userData.userTypeCode === UserType.ShopOwner ? item.userId : undefined
      )
      .subscribe(success => {
        this.isPerformingCashout$.next(false);
        if (!success) {
          return;
        }

        const data = this.cashoutQuery.getActive();

        if (data) {
          if (!data.cashoutResponse.success) {
            this.sendCashoutFailureEvent(item, data.cashoutResponse.userMessage);
            this.showCashoutConfirmResponseModal('fail', data.cashoutResponse.userMessage);
            return;
          } else {
            if (data.cashoutResponse.cashoutId >= 0 && data.cashoutResponse.responseCode === 1) {
              this.cashoutSuccess(data);
            } else {
              // For some reason, we can receive a cashout was successful from BE, but cashout still does not go through...
              if (
                [
                  CashoutResponseCodes.OfferedCashoutMismatch,
                  CashoutResponseCodes.OfferedCashoutValueDiffersFromComputed,
                  CashoutResponseCodes.TimeOut,
                ].includes(data.cashoutResponse.responseCode)
              ) {
                // Cashout value not valid, User can refresh
                this.showCashoutConfirmResponseModal('refresh');
              } else {
                this.showCashoutConfirmResponseModal('fail', data.cashoutResponse.userMessage);
              }
            }
          }
        }
      });
  }

  handleCloseCashoutConfirmResponseModal = () => {
    // If bet is successfully cashed out, then it is not open anymore
    const activeCashout = this.cashoutQuery.getActive();

    if (this.cashoutConfirmResponseModalState$.value === 'fail') {
      // If it failed, the cashout might have changed, need to refresh it.
      this.cashoutService.refreshCashout(activeCashout.id, activeCashout.couponCode, true).subscribe();
    }
    // Reset modal states
    this.cashoutConfirmResponseModalState$.next('noresponse');
    this.cashoutConfirmResponseModalDetails$.next('');
    this.isCashoutConfirmModalOpen$.next(false);
    this.cashoutStore.cashoutInProgress(false);
  };

  handleRefreshCashoutFromModal = () => {
    const activeCashout = this.cashoutQuery.getActive();
    this.cashoutService
      .refreshCashout(activeCashout.id, activeCashout.couponCode, true)
      .subscribe(result =>
        result ? this.cashoutConfirmResponseModalState$.next('noresponse') : this.cashoutConfirmResponseModalState$.next('fail')
      );
  };

  private pendingListCheck(item: CashoutModel) {
    // Check if this coupon's cashout is in evaluation state
    const cashoutRequested = this.cashoutQuery.isInPendingList(undefined, item.couponCode);

    this.cashoutStore.setActive(item.id);
    this.cashoutStore.cashoutRequested(cashoutRequested);

    return cashoutRequested;
  }

  /**
   * Check if user is the same as the one that created the coupon
   */
  private userIdCheck$(): Observable<boolean> {
    return this.item$.pipe(
      map(item => (item ? this.accountQuery.isAuthenticated && Number(this.accountQuery.userData.id) === Number(item.userId) : false))
    );
  }

  /**
   * For shop owners, check if the coupon has been created by a subordinate
   */
  private shopOwnerCheck$(): Observable<boolean> {
    return this.accountQuery.isAuthenticated
      ? this.item$.pipe(map(item => (item ? this.shopOwnerService.isSubUser$(Number(item.userId)) : of(false)))).pipe(mergeAll())
      : of(true); // If not logged in, default to USER type so shop owner check is bypassed
  }

  /**
   * Send cashout action events for analytics
   */

  private sendCashoutInitiatedEvent(cashoutData: CashoutModel): void {
    const eventObj = {
      event: CashoutActions.CashoutInitiated,
      user_id: this.accountQuery.userData?.id,
      amount: cashoutData?.betCashout?.valueNet,
      location: this.parentComponent,
    };
    this.dataLayerService.createDataLayerEvent(eventObj);
  }

  private sendCashoutSubmittedEvent(cashoutData: CashoutModel): void {
    const eventObj = {
      event: CashoutActions.CashoutSubmitted,
      user_id: this.accountQuery.userData?.id,
      amount: cashoutData?.betCashout?.valueNet,
    };
    this.dataLayerService.createDataLayerEvent(eventObj);
  }

  private sendCashoutSuccessEvent(cashoutResponse): void {
    const eventObj = {
      event: CashoutActions.CashoutSuccess,
      user_id: this.accountQuery.userData?.id,
      amount: cashoutResponse?.cashoutValue,
    };
    this.dataLayerService.createDataLayerEvent(eventObj);
  }

  private sendCashoutFailureEvent(cashoutData: CashoutModel, errorMessage: string): void {
    const eventObj = {
      event: CashoutActions.CashoutFailed,
      user_id: this.accountQuery.userData?.id,
      amount: cashoutData?.betCashout?.valueNet,
      errorMessage,
    };
    this.dataLayerService.createDataLayerEvent(eventObj);
  }

  private cashoutSuccess(data: CashoutModel): void {
    if (data.cashoutResponse.cashoutAccepted) {
      this.accountService.updateBalance();
      this.sendCashoutSuccessEvent(data.cashoutResponse);
      this.showCashoutConfirmResponseModal('success', this.currencyFormatPipe.transform(data.cashoutResponse.cashoutValue));

      // Firebase App Event
      const fbEventId = FirebaseEvent.CashoutSuccess;
      const additionalParams = {
        userID: this.accountQuery.userData.id || '',
      };
      this.dataLayerService.logFirebaseEvent(fbEventId, additionalParams);
    } else {
      this.showCashoutConfirmResponseModal(
        'pending',
        data.cashoutResponse.userMessage ||
          $localize`Cashout is being evaluated by an operator. You will be notified shortly with the result.`
      );

      this.cashoutStore.setActive(data.id);
      this.cashoutStore.cashoutRequested(true);

      this.cashoutService.addToPendingList(data.cashoutResponse.cashoutId, data.cashoutResponse.couponCode);
    }
  }

  private readonly showCashoutConfirmResponseModal = (state: CashoutConfirmResponseModalState, details?: string) => {
    this.cashoutConfirmResponseModalState$.next(state);
    details && this.cashoutConfirmResponseModalDetails$.next(details);
  };
}
