<div class="event-details">
  <div class="selection-name">
    {{ odd.selectionName }}
  </div>
  <div class="market-event-wrapper">
    <div class="market-name">
      <span>{{ odd.marketName }} -</span>
    </div>
    <div class="event-name">{{ odd.eventName }}</div>
  </div>
</div>

<div class="odd-details">
  <div class="odd-value">
    <div class="normal-odd-value">
      <i *ngIf="odd.unboostedOddValue" class="odds-boost-badge"></i>
      <span>{{ odd.oddValue | number : '1.2-2' }}</span>
    </div>
    <div *ngIf="odd.unboostedOddValue" class="unboosted-odd-value">
      {{ odd.unboostedOddValue | number : '1.2-2' }}
    </div>
  </div>
</div>
